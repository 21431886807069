import React, { useState } from 'react'
import { BarcodeScanner } from 'react-barcode-detection'
import 'animate.css'

const bb = {
  stroke: "#fff",
  radii: 10,
  lineWidth: 4,
  gap: 0.5,
  margin: 0.1
}
const cp = {
  fill: "rgba(0, 255, 0, 0.4)"
}

export const ScannerModal = ({ resolve, reject }) => {
  const type = 'SnapAndHold'
  const api = true
  const quirc = false
  const jsqr = true
  const [data, setData] = useState()
  const Component = { SnapAndHold }[type]
  const methods = []

  if (api) {
    methods.push("api");
  }

  if (quirc) {
    methods.push("quirc");
  }

  if (jsqr) {
    methods.push("jsqr");
  }

  const accept = () => {
    resolve(data !== undefined ? data : '')
    closeModal()
  }

  const cancel = () => {
    resolve('')
    closeModal()
  }

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  return (
    <div className='modal-card-container d-flex justify-content-center align-items-center'>
      <div className='card modal-card animate__animated animate__fadeIn'>
        <div className='card-header card-header-primary'>GIA ESCANER</div>
        <div className='card-body'>
          <div className="App">
            <Component use={methods.join(",")} data={data} setData={setData} />
          </div>
        </div>
        <div className='card-footer'>
          <div className='text-end'>
            <button className='btn btn-primary me-1' onClick={cancel}>CANCELAR</button>
            <button className='btn btn-primary' onClick={accept}>ACEPTAR</button>
          </div>
        </div>
      </div>
    </div>
  )
}

function SnapAndHold({ use, data, setData }) {
  // const [data, setData] = useState();
  const [snapshot, setSnapshot] = useState();
  const acceptFormats =
    "aztec,code_128,code_39,code_93,codabar,data_matrix,ean_13,ean_8,itf,pdf417,qr_code,upc_a,upc_e";
  return (
    <div>
      <BarcodeScanner
        boundingBox={bb}
        cornerPoints={cp}
        use={use}
        accept={acceptFormats}
        onData={setData}
        onSnapshot={setSnapshot}
      >
        <NoCamera />
      </BarcodeScanner>
      <BarcodeData type="overlay" data={data} />
      {snapshot && <ClearButton onClick={snapshot.clear} />}
    </div>
  );
}

function BarcodeData({ data, type }) {
  const classList = ["BarcodeData", type];
  let content;
  try {
    const url = new URL(data);
    content = (
      <a href={url} target="_blank" rel="noreferrer">
        {data}
      </a>
    );
  } catch (err) {
    content = data;
  }
  return <div className={classList.join(" ")}>{content}</div>;
}

function ClearButton({ onClick }) {
  return (
    <button className='btn btn-primary ClearButton' onClick={onClick}>ESCANEAR DE NUEVO</button>
  );
}

function NoCamera() {
  return (
    <div className="no-camera">
      <div className="sign">&#9940;</div>
    </div>
  );
}