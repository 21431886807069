import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { modificarRepostaje, obtenerDatosRepostaje, obtenerDatosSolicitudNuevoRepostaje, verRepostaje } from '../redux/actions/repostajesActions'
import { RepostajeModificarForm } from '../components/RepostajeModificarForm'

export const RepostajeModificar = () => {
  const dispatch = useDispatch()
  const { vehiculoId, repostajeId } = useParams()

  const {
    listaAreas,
    listaGasolineras,
    listaTiposCombustible,
  } = useSelector(({ repostajesReducer }) => repostajesReducer)

  const { repostaje } = useSelector(({ repostajesReducer }) => repostajesReducer)

  useEffect(() => {
    dispatch(obtenerDatosRepostaje(vehiculoId, repostajeId))
    dispatch(obtenerDatosSolicitudNuevoRepostaje())
    return () => {
      dispatch(modificarRepostaje({ 'ESTADO_REPOSTAJE_MODIFICADO': '', 'MENSAJE_ERROR': '' }))
      dispatch(verRepostaje({}))
    }
  }, [dispatch, repostajeId, vehiculoId])

  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title='MODIFICAR REPOSTAJE' />
        {
          (Object.entries(repostaje).length > 0 && Object.entries(listaAreas).length > 0 && Object.entries(listaGasolineras).length > 0 && Object.entries(listaTiposCombustible).length > 0)
            ?
            <RepostajeModificarForm repostaje={repostaje} listaAreas={listaAreas} listaGasolineras={listaGasolineras} listaTiposCombustible={listaTiposCombustible} vehiculoId={vehiculoId} repostajeId={repostajeId} />
            :
            <div className='alert alert-primary text-center no-show' id='alert' role='alert'>
              CARGANDO FORMULARIO
            </div>
        }
      </div>
    </PageLayout>
  )
}

