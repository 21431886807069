import React from 'react'
import ReactDOM from 'react-dom/client'
import { SeleccionTipoColectivoModal } from '../components/SeleccionTipoColectivoModal'

export const SeleccionTipoColectivo = (centrosDeDia) => {
  return new Promise((resolve, reject) => {
    showModal(resolve, reject, centrosDeDia)
  })
}


const showModal = (resolve, reject, centrosDeDia) => {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')
  div.setAttribute('id', 'modal-container')
  body.appendChild(div)
  const modalContainer = ReactDOM.createRoot(div)
  modalContainer.render(<SeleccionTipoColectivoModal resolve={resolve} reject={reject} centrosDeDia={centrosDeDia} />)
}