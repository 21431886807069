import React, { useState } from 'react'
import 'animate.css'

export const SeleccionTipoColectivoModal = ({ resolve, reject, centrosDeDia }) => {
  const [tipoColectivo, setTipoColectivo] = useState('')
  const [centroDeDia, setCentroDeDia] = useState('0')

  const accept = () => {
    if (tipoColectivo === 'centroDeDia' && (centroDeDia === '' | centroDeDia === '0')) {
      document.getElementById('centrosDeDia').classList.add('form-control-error')
    } else {
      resolve({ 'tipoColectivo': tipoColectivo, 'centroDeDia': centroDeDia })
      closeModal()
    }
  }

  const cancel = () => {
    resolve('')
    closeModal()
  }

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  const handleChange = (e) => {
    setCentroDeDia(e.target.value)
  }

  return (
    <div className='modal-card-container d-flex justify-content-center align-items-center'>
      <div className='card modal-card animate__animated animate__fadeIn'>
        <div className='card-header card-header-primary'>GIA CONFIRMACION</div>
        <div className='card-body text-center'>
          <div className='btn-group' role='group' aria-label='Basic radio toggle button group'>
            <input type='radio' className='btn-check' name='btnradio' id='btnradio1' value={tipoColectivo} onChange={() => setTipoColectivo('colectivoNormal')} autoComplete='off' />
            <label className='btn btn-outline-primary' htmlFor='btnradio1'>COLECTIVO NORMAL</label>
            <input type='radio' className='btn-check' name='btnradio' id='btnradio2' value={tipoColectivo} onChange={() => setTipoColectivo('centroDeDia')} autoComplete='off' />
            <label className='btn btn-outline-primary' htmlFor='btnradio2'>CENTRO DE DÍA</label>
          </div>
          <div className={`row mt-3 mb-3 ${tipoColectivo !== 'centroDeDia' && 'no-show'}`}>
            <div className='col'>
              <select
                className='form-select'
                id='centrosDeDia'
                name='centrosDeDia'
                value={centroDeDia}
                onChange={handleChange}
              >
                <option key={'0'} value='0'>Seleccione un centro de día...</option>
                {
                  centrosDeDia &&
                  centrosDeDia.map(centroDia => {
                    return (
                      <option key={centroDia.IN_CENTRO_DIA_ID} value={centroDia.IN_CENTRO_DIA_ID}>{centroDia.NOMBRE_CENTRO_DIA}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>


        </div>
        <div className='card-footer'>
          <div className='text-end'>
            <button className='btn btn-primary me-1' onClick={cancel}>CANCELAR</button>
            <button className='btn btn-primary' onClick={accept} disabled={tipoColectivo === '' ? true : false}>ACEPTAR</button>
          </div>
        </div>
      </div>
    </div>
  )
}