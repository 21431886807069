import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { obtenerListaServicios, verListaServicios } from '../redux/actions/serviciosActions'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { Servicio } from '../components/Servicio'
import { AlertaListaSinResultados } from '../components/AlertaListaSinResultados'
import { AlertaServiciosDesdeMovil } from '../components/AlertaServiciosDesdeMovil'

export const Servicios = () => {
  const dispatch = useDispatch()
  const { inIndicativoServicioId, permitirGestionServiciosTelefono } = useSelector(({ loginReducer }) => loginReducer)
  const { listaServicios } = useSelector(({ serviciosReducer }) => serviciosReducer)

  const orientation = window.screen.orientation.type
  const widthScreen = window.screen.width
  const heightScreen = window.screen.height

  let esPantallaTelefono = false

  if ((orientation === 'landscape-primary' && widthScreen < Number(750) && heightScreen < Number(370)) || (orientation === 'portrait-primary' && widthScreen < Number(370) && heightScreen < Number(750))) {
    esPantallaTelefono = true
  }

  useEffect(() => {
    dispatch(obtenerListaServicios(inIndicativoServicioId))
    return () => {
      dispatch(verListaServicios([]))
    }
  }, [dispatch, inIndicativoServicioId])

  return (
    <PageLayout>
      <div className='content px-3'>
        <TitlePage title='SERVICIOS' />
        {/* {
          !esPantallaTelefono || (esPantallaTelefono && permitirGestionServiciosTelefono)
            ?
            <> */}
        <div className='list-buttons mb-3 text-end'>
          <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
        </div>
        {
          listaServicios.length > 0
            ?
            listaServicios.map(servicio => {
              return (
                <Servicio key={servicio.SERVICIO_ID} servicio={servicio} />
              )
            })
            :
            <AlertaListaSinResultados />
        }
        {/* </>
            :
            <AlertaServiciosDesdeMovil />
        } */}
      </div>
    </PageLayout >
  )
}