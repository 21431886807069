import { types } from '../types'

const initialValues = {
  isOpenSidebar: false
}

export const sidebarReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.toggleSidebar:
      return {
        isOpenSidebar: action.payload.isOpenSidebar
      }
    default:
      return state
  }
}