import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import uniqid from 'uniqid'
import { obtenerStockMaterialVehiculo } from '../redux/actions/stockMaterialActions'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'

export const StockMaterial = () => {
  const dispatch = useDispatch()

  const { inVehiculoId } = useSelector(({ loginReducer }) => loginReducer)
  const { stockMaterialVehiculo } = useSelector(({ stockMaterialReducer }) => stockMaterialReducer)

  useEffect(() => {
    dispatch(obtenerStockMaterialVehiculo(inVehiculoId))
  }, [dispatch, inVehiculoId])

  return (
    <PageLayout>
      <div className='content'>
        <TitlePage title='MATERIAL EN VEHICULO' />
        <div className='text-end mb-3'>
          <NavLink to='/reponer-material-vehiculo' className='btn btn-primary me-1'>NUEVA REPOSICION</NavLink>
          <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
        </div>
        <div className='mb-3'>
          <table className='table table-hover'>
            <thead>
              <tr>
                <th scope='col'>Material</th>
                <th scope='col' className='text-end'>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {
                stockMaterialVehiculo.length > 0
                &&
                stockMaterialVehiculo.map(material => {
                  return (
                    <tr key={uniqid()}>
                      <td className='ps-3'>{material.MATERIAL_DESCRIPCION}</td>
                      <td className='pe-3 text-end'>{material.MATERIAL_CANTIDAD}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </PageLayout>
  )
}
