import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfirmModal } from '../components/ConfirmModal'

export const Confirm = (msg, acceptBtn = 'OK', cancelBtn = '') => {
  return new Promise((resolve, reject) => {
    showModal(resolve, reject, msg, acceptBtn, cancelBtn)
  })
}


const showModal = (resolve, reject, msg, acceptBtn, cancelBtn) => {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')
  div.setAttribute('id', 'modal-container')
  body.appendChild(div)
  const modalContainer = ReactDOM.createRoot(div)
  modalContainer.render(<ConfirmModal resolve={resolve} reject={reject} msg={msg} acceptBtn={acceptBtn} cancelBtn={cancelBtn} />)
}