import { types } from '../types'
import { fetchData } from '../fetchData'

export const obtenerStockMaterialVehiculo = (inVehiculoId) => {
  const proceso = 'A_MATERIAL_CONSUMO_STOCK.PRO'
  let params = `?IN_VEHICULO_ID=${inVehiculoId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => { dispatch(listarStockMaterialVehiculo(data.LISTA_MATERIAL)) })
  }
}

export const obtenerListaAlmacenes = () => {
  const proceso = 'A_MATERIAL_CONSUMO_ALMACENES.PRO'
  const url = `${process.env.REACT_APP_APIVEL}${proceso}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => { dispatch(listarAlmacenes(data.LISTA_ALMACENES)) })
  }
}

export const nuevaReposicionMaterial = ({ camilleroId, conductorId, indicativoServicioId, vehiculoId, almacen, cantidad, material }) => {
  const proceso = 'A_REPOSICION_MATERIAL_CONSUMO.PRO'
  let params = `?IN_ALMACEN_ID=${almacen}`
  params = params + `&IN_CAMILLERO_ID=${camilleroId}`
  params = params + `&IN_CANTIDAD_REPUESTA=${cantidad}`
  params = params + `&IN_CONDUCTOR_ID=${conductorId}`
  params = params + `&IN_INDICATIVO_SERVICIO_ID=${indicativoServicioId}`
  params = params + `&IN_MATERIAL_CONSUMO_ID=${material}`
  params = params + `&IN_VEHICULO_ID=${vehiculoId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => { })
  }
}

export const listarStockMaterialVehiculo = (data) => {
  return {
    type: types.listarStockMaterialVehiculo,
    payload: {
      stockMaterialVehiculo: data
    }
  }
}
export const listarAlmacenes = (data) => {
  return {
    type: types.listarAlmacenes,
    payload: {
      listaAlmacenes: data
    }
  }
}