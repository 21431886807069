import { useEffect } from 'react'
import { Alert } from './Alert'

export const useTestConnection = (testConnection) => {
  useEffect(() => {
    console.log('Comprobando conexión a internet')
    if (testConnection) {
      if (!window.navigator.onLine) {
        Alert('PARECE QUE NO HAY CONEXIÓN A INTERNET. COMPRUEBE SU CONEXIÓN Y VUELVA A INTENTARLO.', 'ACEPTAR')
      }
    }
  }, [testConnection])
}
