import React from 'react'
import ReactDOM from 'react-dom/client'
import { ServicioFinalizarModal } from '../components/ServicioFinalizarModal'

export const ServicioFinalizar = ({ servicio }, codigoFinalizacion) => {
  return new Promise((resolve, reject) => {
    showModal(resolve, reject, servicio, codigoFinalizacion)
  })
}

const showModal = (resolve, reject, servicio, codigoFinalizacion) => {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')
  div.setAttribute('id', 'modal-container')
  body.appendChild(div)
  const modalContainer = ReactDOM.createRoot(div)
  modalContainer.render(
    <ServicioFinalizarModal
      resolve={resolve}
      reject={reject}
      servicio={servicio}
      codigoFinalizacion={codigoFinalizacion}
    />
  )
}
