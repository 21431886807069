import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { NavLink } from 'react-router-dom'
import { EvaluacionMentorConceptos } from './EvaluacionMentorConceptos'

export const EvaluacionMentor = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(false)
  const [dataResp, setDataResp] = useState(null)
  const [loadingResp, setLoadingResp] = useState(null)
  const [errorResp, setErrorResp] = useState(false)
  const { inConductorId, inIndicativoServicioId } = useSelector(({ loginReducer }) => loginReducer)

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_APIVEL}A_REGISTRO_EVALUACION_MENTOR.PRO?IN_CONDUCTOR_ID=${inConductorId}&IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`)
      const result = await response.json()
      setData(result)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const actualizaConceptos = async () => {
    setLoadingResp(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_APIVEL}A_REGISTRO_EVALUACION_MENTOR_ACTUALIZA?IN_JSON_EVALUACION=${JSON.stringify(data)}`)
      const result = await response.json()
      setDataResp(result)
    } catch (error) {
      setErrorResp(error)
    } finally {
      setLoadingResp(false)
    }
  }

  const enviar = () => {
    actualizaConceptos()
  }

  useEffect(() => {
    !data && fetchData()
  }, [data])


  return (
    <PageLayout>
      <div className='content px-3'>
        <TitlePage title='VALORACIÓN MENTOR' />
        {error && <div className='alert alert-danger'>{error.message}</div>}
        {
          (data && data.EVALUACION_MENTOR_ID === '0')
          &&
          <div className='alert alert-primary'>No hay asignado ningún alumno en pácticas</div>
        }
        {
          (dataResp && dataResp.STATUS === '1')
          &&
          <div className='alert alert-success'>La valoración se envió correctamente.</div>
        }
        {
          (data && data.EVALUACION_MENTOR_ID !== '0')
          &&
          <>
            <h5 className='font-weight-bold'>{`ALUMNO: ${data.EVALUACION_MENTOR_ALUMNO}`}</h5>
            <EvaluacionMentorConceptos data={data} setData={setData} />
            {
              !dataResp &&
              <button className='btn btn-primary' onClick={enviar}>{loadingResp ? 'ENVIANDO VALORACIONES' : 'ENVIAR VALORACIÓN'}</button>
            }
            {
              (dataResp && dataResp.STATUS === '1')
              &&
              <NavLink to='/registros' className='btn btn-primary'>VOLVER</NavLink>
            }
          </>
        }
      </div>
    </PageLayout>
  )
}
