import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { compruebaCuadrante } from '../redux/actions/loginActions'

export const useCompruebaCuadrante = (testCuadrante) => {
  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector(({ loginReducer }) => loginReducer)

  useEffect(() => {
    if (testCuadrante) {
      console.log('Comprobando cuadrante')
      isLoggedIn && dispatch(compruebaCuadrante())
    }
  }, [dispatch, isLoggedIn, testCuadrante])
}
