export const types = {
  login: 'login',
  loginError: 'loginError',
  logout: 'logout',
  logoutError: 'logoutError',
  actualizarMensajeAvisos: 'actualizarMensajeAvisos',

  toggleSidebar: 'toggleSidebar',

  gestionComida: 'gestionComida',
  resetearMensajeComida: 'resetearMensajeComida',

  cargarListaContactos: 'cargarListaContactos',

  listarRepostajes: 'listarRepostajes',
  nuevoRepostaje: 'nuevoRepostaje',
  solicitudDatosNuevoRepostaje: 'solicitudDatosNuevoRepostaje',
  verRepostaje: 'verRepostaje',
  modificarRepostaje: 'modificarRepostaje',

  cambioVehiculo: 'cambioVehiculo',
  intercambioVehiculo: 'intercambioVehiculo',
  actualizarDatosVehiculo: 'actualizarDatosVehiculo',

  listarAlmacenesOxigeno: 'listarAlmacenesOxigeno',
  recogerOxigeno: 'recogerOxigeno',
  devolverOxigeno: 'devolverOxigeno',

  listarStockMaterialVehiculo: 'listarStockMaterialVehiculo',
  listarAlmacenes: 'listarAlmacenes',

  listarServicios: 'listarServicios',
  verServicio: 'verServicio',
  verServiciosActuales: 'verServiciosActuales',
  verServicioGenerico: 'verServicioGenerico',
  listarMaterialConsumido: 'listarMaterialConsumido',
  listarMaterialSeleccionable: 'listarMaterialSeleccionable',

  listarCitasTaller: 'listarCitasTaller',
  verCitaTaller: 'verCitaTaller',
  solicitarNuevoTrabajoCitaTaller: 'solicitarNuevoTrabajoCitaTaller',

  listarRegistrosCalidad: 'listarRegistrosCalidad',
  verRegistroCalidad: 'verRegistroCalidad',

  solicitarCambioturno: 'solicitarCambioTurno',

  listarCentrosDeDia: 'listarCentrosDeDia',

  listarCodigosActuacion: 'listarCodigosActuacion',
  listarAtenciones: 'listarAtenciones',

  actualizarPedirFotosInicioJornada: 'actualizarPedirFotosInicioJornada'
}