import React, { useState } from 'react'
import 'animate.css'

export const CitaTAllerNuevoTrabajoModal = ({ resolve, reject, msg, acceptBtn, cancelBtn = '' }) => {
  const [trabajoSolicitado, setTrabajoSolicitado] = useState('')

  const accept = () => {
    resolve(trabajoSolicitado)
    closeModal()
  }

  const cancel = () => {
    resolve('')
    closeModal()
  }

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  const handleChange = (e) => {
    setTrabajoSolicitado(e.target.value)
  }

  return (
    <div className='modal-card-container d-flex justify-content-center align-items-center'>
      <div className='card modal-card animate__animated animate__fadeIn'>
        <div className='card-body'>
          <div className="form-floating">
            <textarea
              className="form-control"
              placeholder="Describa el trabajo a realizar"
              id="textNuevoTrabajo"
              onChange={handleChange}
            >
            </textarea>
            <label htmlFor="textNuevoTrabajo">Describa el trabajo a realizar</label>
          </div>
          <div className='text-end mt-3'>
            <button className='btn btn-primary me-1' onClick={cancel}>CANCELAR</button>
            <button className='btn btn-primary' onClick={accept}>ACEPTAR</button>
          </div>
        </div>
      </div>
    </div >
  )
}