import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { OxigenoRecogerForm } from '../components/OxigenoRecogerForm'
import { obtenerListaAlmacenesOxigeno, recogerOxigeno } from '../redux/actions/oxigenoActions'

export const OxigenoRecoger = () => {
  const dispatch = useDispatch()

  const { listaAlmacenesOxigeno, respuestaRecogerOxigeno } = useSelector(({ oxigenoReducer }) => oxigenoReducer)
  const {
    inConductorId,
    inVehiculoId,
    inIndicativoServicioId
  } = useSelector(({ loginReducer }) => loginReducer)

  useEffect(() => {
    dispatch(obtenerListaAlmacenesOxigeno())
    return () => {
      dispatch(recogerOxigeno(''))
    }
  }, [dispatch])

  return (
    <PageLayout>
      <div className='content d-flex flex-column '>
        <TitlePage title='RECOGER OXIGENO' />
        {
          Object.keys(listaAlmacenesOxigeno).length > 0
            ?
            <OxigenoRecogerForm
              listaAlmacenesOxigeno={listaAlmacenesOxigeno}
              inConductorId={inConductorId}
              inIndicativoServicioId={inIndicativoServicioId}
              inVehiculoId={inVehiculoId}
              respuestaRecogerOxigeno={respuestaRecogerOxigeno}
            />
            :
            <div className='alert alert-primary text-center no-show' id='alert' role='alert'>
              CARGANDO FORMULARIO
            </div>
        }
      </div>
    </PageLayout>
  )
}
