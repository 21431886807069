import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../redux/store'
import { ServicioFinalizarModalForm } from './ServicioFinalizarModalForm'

export const ServicioFinalizarModal = ({ resolve, reject, servicio, codigoFinalizacion }) => {
  return (
    <Provider store={store}>
      <ServicioFinalizarModalForm
        resolve={resolve}
        reject={reject}
        servicio={servicio}
        codigoFinalizacion={codigoFinalizacion}
      />
    </Provider>
  )
}