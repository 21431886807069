import React from 'react'
import ReactDOM from 'react-dom/client'
import { ScannerModal } from '../components/ScannerModal'

export const Scanner = () => {
  return new Promise((resolve, reject) => {
    showScannerModal(resolve, reject)
  })
}


const showScannerModal = (resolve, reject) => {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')
  div.setAttribute('id', 'modal-container')
  body.appendChild(div)
  const modalContainer = ReactDOM.createRoot(div)
  modalContainer.render(<ScannerModal resolve={resolve} reject={reject} />)
}