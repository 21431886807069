import React from 'react'
import { NavLink } from 'react-router-dom'

export const RegistroAtencion = ({ registro, servicioId, anio, tabla }) => {
  // const { inVehiculoId } = useSelector(({ loginReducer }) => loginReducer)

  return (
    <div className='card mt-3'>
      <div className='card-header'>
        <div className='d-flex fw-bold'>
          <div className='me-auto'>{registro.ATENCION_FECHA}</div>
          <div>{registro.ATENCION_HORA.substr(0, 5)} hs.</div>
        </div>
      </div>
      <div className='card-body py-4'>
        <p className='m-0'><strong>NOMBRE:</strong> {registro.ATENCION_NOMBRE_APELLIDOS}</p>
        <p className='m-0'><strong>DNI:</strong> {registro.ATENCION_DNI}</p>
        <p className='m-0'><strong>EDAD:</strong> {registro.ATENCION_EDAD} AÑOS</p>
      </div>
      <div className='card-footer'>
        <div className='d-flex'>
          <div className='me-auto'></div>
          <div>
            <NavLink to={`/servicio-generico-modificar-registro-atencion/${servicioId}/${anio}/${tabla}/${registro.IN_ATENCION_ID}`} className='btn btn-primary'>
              VER REGISTRO
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}
