import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { loadState } from './loadState'
import { saveState } from './saveState'

import { loginReducer } from '../redux/reducers/loginReducer'
import { sidebarReducer } from '../redux/reducers/sidebarReducer'
import { comidaReducer } from '../redux/reducers/comidaReducer'
import { contactosReducer } from '../redux/reducers/contactosReducer'
import { serviciosReducer } from '../redux/reducers/serviciosReducer'
import { repostajesReducer } from '../redux/reducers/repostajesReducer'
import { cambioVehiculoReducer } from '../redux/reducers/cambioVehiculoReducer'
import { oxigenoReducer } from './reducers/oxigenoReducer'
import { stockMaterialReducer } from './reducers/stockMaterialReducer'
import { citasTallerReducer } from './reducers/citasTallerReducer'
import { calidadReducer } from './reducers/calidadReducer'
import { cambioTurnoReducer } from './reducers/cambioTurnoReducer'
import { centrosDeDiaReducer } from './reducers/centrosDeDiaReducer'
import { registroAtencionesReducer } from './reducers/registroAtencionesReducer'

const reducers = combineReducers({
  loginReducer: loginReducer,
  sidebarReducer: sidebarReducer,
  comidaReducer: comidaReducer,
  contactosReducer: contactosReducer,
  serviciosReducer: serviciosReducer,
  repostajesReducer: repostajesReducer,
  cambioVehiculoReducer: cambioVehiculoReducer,
  oxigenoReducer: oxigenoReducer,
  stockMaterialReducer: stockMaterialReducer,
  citasTallerReducer: citasTallerReducer,
  calidadReducer: calidadReducer,
  cambioTurnoReducer: cambioTurnoReducer,
  centrosDeDiaReducer: centrosDeDiaReducer,
  registroAtencionesReducer: registroAtencionesReducer
})

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
const rootReducer = (state, action) => {
  if (action.type === 'logout') {
    state = undefined
  }
  return reducers(state, action)
}
const initialState = loadState()
export const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)))
store.subscribe(() => { saveState(store.getState()) })