import React from 'react'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { NavLink } from 'react-router-dom'

export const IncidenciaGeneral = () => {
  return (
    <PageLayout>
      <div className='content'>
        <TitlePage title='INCIDENCIA GENERAL' />
        <div className='text-end mb-3'>
          <NavLink to='/registros
          ' className='btn btn-primary me-1'>VOLVER</NavLink>
          <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
        </div>
      </div>
    </PageLayout>
  )
}