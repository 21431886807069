import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkLogout } from '../redux/actions/loginActions'
import { logoutError as resetLogoutError } from '../redux/actions/loginActions'
import { Confirm } from '../helpers/Confirm'
import { Alert } from '../helpers/Alert'
import { FiLogOut } from 'react-icons/fi'

export const LogoutButton = () => {
  const dispatch = useDispatch()
  const { logoutError, inIndicativoServicioId, inCuadranteId } = useSelector(({ loginReducer }) => loginReducer)

  const logout = async () => {
    const resp = await Confirm('SE VA A FINALIZAR JORNADA. ¿DESEA CONTINUAR?', 'ACEPTAR', 'CANCELAR')
    resp && dispatch(checkLogout({ inIndicativoServicioId: inIndicativoServicioId, inCuadranteId: inCuadranteId }))
  }

  useEffect(() => {
    const resetLogoutErrorState = () => { dispatch(resetLogoutError(false)) }
    logoutError && Alert('LA JORNADA NO SE PUEDE FINALIZAR. CONTACTE CON SU SUPERVISOR', 'ACEPTAR')
    resetLogoutErrorState()
  }, [dispatch, logoutError])



  return (
    <button type="button" className='btn btn-outline-light' onClick={logout}>
      <span className='align-middle'>FINALIZAR JORNADA </span><FiLogOut className='align-middle' />
    </button>
  )
}
