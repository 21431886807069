import React from 'react'
import { useSelector } from 'react-redux'
import { LogoutButton } from './LogoutButton'
import { SideBarOpenButton } from './SideBarOpenButton'
import { UserInfoButton } from './UserInfoButton'

export const Header = () => {
  const { isLoggedIn } = useSelector(({ loginReducer }) => loginReducer)

  return (
    <>
      {
        isLoggedIn
        &&
        <div className='header sticky-top d-flex align-items-center'>
          <SideBarOpenButton />
          <UserInfoButton />
          <LogoutButton />
        </div>
      }
    </>
  )
}
