import React from 'react'
import 'animate.css'

export const ConfirmModal = ({ resolve, reject, msg, acceptBtn, cancelBtn = '' }) => {

  const accept = () => {
    resolve(true)
    closeModal()
  }

  const cancel = () => {
    resolve(false)
    closeModal()
  }

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  return (
    <div className='modal-card-container d-flex justify-content-center align-items-center'>
      <div className='card modal-card animate__animated animate__fadeIn'>
        <div className='card-header card-header-primary'>GIA CONFIRMACION</div>
        <div className='card-body'>
          <p className='card-text mx-3 my-3'>{msg}</p>
        </div>
        <div className='card-footer'>
          <div className='text-end'>
            {
              cancelBtn !== '' && <button className='btn btn-primary me-1' onClick={cancel}>{cancelBtn}</button>
            }
            <button className='btn btn-primary' onClick={accept}>{acceptBtn}</button>
          </div>
        </div>
      </div>
    </div>
  )
}
