import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import { Confirm } from '../helpers/Confirm'
import { enviarDatosSolicitudCambioTurno, datosSolicitudCambioTurno } from '../redux/actions/cambioTurnoActions'

export const SolicitudCambioTurnoForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { respuestaSolicitudCambioTurno, datosCubreTurno, datosSolicitante } = useSelector(({ cambioTurnoReducer }) => cambioTurnoReducer)

  const formik = useFormik({
    initialValues: {
      ind_solicitante: '',
      email_solicitante: '',
      tlf_solicitante: '',
      ind_cubreturno: '',
      ind_servicio_cubrir: '',
      motivo: '',
      fecha_inicio_cobertura: moment().add(1, 'days').format('YYYY-MM-DD'),
      hora_inicio_cobertura: moment().format('HH:MM'),
      fecha_fin_cobertura: moment().add(1, 'days').format('YYYY-MM-DD'),
      hora_fin_cobertura: moment().format('HH:MM')
    },
    validationSchema: Yup.object(
      {
        ind_solicitante: Yup.string().required('campo requerido'),
        tlf_solicitante: Yup.string().required('campo requerido'),
        email_solicitante: Yup.string().required('campo requerido'),
        ind_cubreturno: Yup.string().required('campo requerido'),
        ind_servicio_cubrir: Yup.string().required('campo requerido'),
        fecha_inicio_cobertura: Yup.date().required('Campo requerido').min(moment().add(1, 'days').format('YYYY-MM-DD'), 'Fecha incorrecta.'),
        fecha_fin_cobertura: Yup.date().required('Campo requerido').min(moment().add(1, 'days').format('YYYY-MM-DD'), 'Fecha incorrecta.')
      }
    ),
    onSubmit: values => {
      setTimeout(async () => {
        const resp = await Confirm('SE VA A REGISTRAR UNA SOLICITUD DE CAMBIO DE TURNO. ¿DESEA CONTNUAR?', 'ACEPTAR', 'CANCELAR')
        if (resp) {
          dispatch(enviarDatosSolicitudCambioTurno(values))
        }
      }, 100)
    },
  })

  useEffect(() => {
    respuestaSolicitudCambioTurno === '1'
      ?
      setTimeout(async () => {
        const resp = await Confirm(`SOLICITUD ENVIADA CORRECTAMENTE Y PENDIENTE DE ACEPTACIÓN DEL SUPERVISOR. SOLICITADO POR ${datosSolicitante} PARA SER CUBIERTO POR ${datosCubreTurno}`, 'ACEPTAR')
        if (resp) {
          navigate('/registros')
        }
      }, 100)
      :
      (respuestaSolicitudCambioTurno === '0')
      &&
      document.querySelector('#alert').classList.remove('no-show')

    return () => {
      const resetCambioTurno = {
        'CREADO': '',
        'DATOS_CUBRE_TURNO': '',
        'DATOS_SOLICITANTE': ''
      }
      dispatch(datosSolicitudCambioTurno(resetCambioTurno))
    }

  }, [respuestaSolicitudCambioTurno, datosCubreTurno, datosSolicitante, navigate, dispatch])

  return (
    <>
      <div className='mb-3 px-3'>
        <form onSubmit={formik.handleSubmit} id='solicitarCambioTurno'>
          <div className='row mb-2'>
            <div className='col-sm-4'>
              <label htmlFor='ind_solicitante' className='form-label'>Indicativo del solicitante</label>
              <div className='input-group mb-3'>
                <input
                  className={`form-control form-control-sm ${(formik.touched.ind_solicitante && formik.errors.ind_solicitante) && 'form-control-error'}`}
                  type='text'
                  name='ind_solicitante'
                  id='ind_solicitante'
                  value={formik.values.ind_solicitante}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className='col-sm-8'>
              <label htmlFor='tlf_solicitante' className='form-label'>Teléfono del solicitante</label>
              <div className='input-group mb-3'>
                <input
                  className={`form-control form-control-sm ${(formik.touched.tlf_solicitante && formik.errors.tlf_solicitante) && 'form-control-error'}`}
                  type='text'
                  name='tlf_solicitante'
                  id='tlf_solicitante'
                  value={formik.values.tlf_solicitante}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col'>
              <label htmlFor='email_solicitante' className='form-label'>Email del solicitante</label>
              <div className='input-group mb-3'>
                <input
                  className={`form-control form-control-sm ${(formik.touched.email_solicitante && formik.errors.email_solicitante) && 'form-control-error'}`}
                  type='email'
                  name='email_solicitante'
                  id='email_solicitante'
                  value={formik.values.email_solicitante}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-sm-4'>
              <label htmlFor='ind_cubreturno' className='form-label'>Indicativo del cubreturno</label>
              <div className='input-group mb-3'>
                <input
                  className={`form-control form-control-sm ${(formik.touched.ind_cubreturno && formik.errors.ind_cubreturno) && 'form-control-error'}`}
                  type='text'
                  name='ind_cubreturno'
                  id='ind_cubreturno'
                  value={formik.values.ind_cubreturno}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className='col-sm-4'>
              <label htmlFor='ind_servicio_cubrir' className='form-label'>Indicativo del servicio a cubrir</label>
              <div className='input-group mb-3'>
                <input
                  className={`form-control form-control-sm ${(formik.touched.ind_servicio_cubrir && formik.errors.ind_servicio_cubrir) && 'form-control-error'}`}
                  type='text'
                  name='ind_servicio_cubrir'
                  id='ind_servicio_cubrir'
                  value={formik.values.ind_servicio_cubrir}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col'>
              <label htmlFor='motivo' className='form-label'>Motivo</label>
              <div className='input-group mb-3'>
                <textarea
                  rows={10}
                  className={`form-control form-control-sm ${(formik.touched.motivo && formik.errors.motivo) && 'form-control-error'}`}
                  type='email'
                  name='motivo'
                  id='motivo'
                  value={formik.values.motivo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                </textarea>
              </div>
            </div>
          </div>
          <div className='row'>
            <span><strong>Fecha y hora del inicio de la cobertura</strong></span>
          </div>
          <div className='row mb-2'>
            <div className='col-4'>
              <label htmlFor='fecha_inicio_cobertura' className='form-label'>Fecha</label>
              <input
                className={`form-control form-control-sm ${(formik.touched.fecha_inicio_cobertura && formik.errors.fecha_inicio_cobertura) && 'form-control-error'}`}
                type='date'
                name='fecha_inicio_cobertura'
                id='fecha_inicio_cobertura'
                value={formik.values.fecha_inicio_cobertura}
                min={moment().add(1, 'days').format('YYYY-MM-DD')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className='col-2'>
              <label htmlFor='hora_inicio_cobertura' className='form-label'>Hora</label>
              <input
                className={`form-control form-control-sm ${(formik.touched.hora_inicio_cobertura && formik.errors.hora_inicio_cobertura) && 'form-control-error'}`}
                type='time'
                name='hora_inicio_cobertura'
                id='hora_inicio_cobertura'
                value={formik.values.hora_inicio_cobertura}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className='row mt-5'>
            <span><strong>Fecha y hora del fin de la cobertura</strong></span>
          </div>
          <div className='row mb-2'>
            <div className='col-4'>
              <label htmlFor='fecha_fin_cobertura' className='form-label'>Fecha</label>
              <input
                className={`form-control form-control-sm ${(formik.touched.fecha_fin_cobertura && formik.errors.fecha_fin_cobertura) && 'form-control-error'}`}
                type='date'
                name='fecha_fin_cobertura'
                id='fecha_fin_cobertura'
                value={formik.values.fecha_fin_cobertura}
                min={moment().add(1, 'days').format('YYYY-MM-DD')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className='col-2'>
              <label htmlFor='hora_fin_cobertura' className='form-label'>Hora</label>
              <input
                className={`form-control form-control-sm ${(formik.touched.hora_fin_cobertura && formik.errors.hora_fin_cobertura) && 'form-control-error'}`}
                type='time'
                name='hora_fin_cobertura'
                id='hora_fin_cobertura'
                value={formik.values.hora_fin_cobertura}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </form>
      </div >
      <div className='text-end mt-auto pt-3 px-3'>
        <div className='alert alert-primary text-center no-show' id='alert' role='alert'>
          AH OCURRIDO UN PROBLEMA. REVISE LOS DATOS INTRODUCIDOS Y VUELVA A INTENTARLO.
        </div>
        <NavLink to='/registros' className='btn btn-primary me-1'>CANCELAR</NavLink>
        <button type='submit' form='solicitarCambioTurno' className='btn btn-primary'>ACEPTAR</button>
      </div>
    </>
  )
}
