import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../redux/store'
import { AppRouter } from '../router/AppRouter'
import { Footer } from './Footer'
import { Header } from './Header'

export const App = () => {
  return (
    <Provider store={store}>
      <div id='app-container'>
        <Header />
        <AppRouter />
        <Footer />
      </div >
    </Provider>
  )
}