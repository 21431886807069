import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { solicitarListaAtenciones } from '../redux/actions/registroAtencionesActions'
import { NavLink } from 'react-router-dom'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { AlertaListaSinResultados } from '../components/AlertaListaSinResultados'
import { RegistroAtencion } from '../components/RegistroAtencion'
import { AlertaServiciosDesdeMovil } from '../components/AlertaServiciosDesdeMovil'

export const ServicioGenericoRegistrosAtenciones = () => {
  const dispatch = useDispatch()
  const { servicioId, servicioAnio, servicioTabla } = useParams()
  const { permitirGestionServiciosTelefono } = useSelector(({ loginReducer }) => loginReducer)
  const { listaAtenciones } = useSelector(({ registroAtencionesReducer }) => registroAtencionesReducer)

  const orientation = window.screen.orientation.type
  const widthScreen = window.screen.width
  const heightScreen = window.screen.height

  let esPantallaTelefono = false

  if ((orientation === 'landscape-primary' && widthScreen < Number(750) && heightScreen < Number(370)) || (orientation === 'portrait-primary' && widthScreen < Number(370) && heightScreen < Number(750))) {
    esPantallaTelefono = true
  }

  useEffect(() => {
    dispatch(solicitarListaAtenciones(servicioId, servicioTabla, servicioAnio))
  }, [dispatch, servicioAnio, servicioId, servicioTabla])


  return (
    <PageLayout>
      <div className='content'>
        <TitlePage title='REGISTRO DE ATENCIONES' />
        {
          !esPantallaTelefono || (esPantallaTelefono && permitirGestionServiciosTelefono)
            ?
            <>
              <div className='text-end mb-3'>
                <NavLink to={`/servicio-generico-nuevo-registro-atencion/${servicioId}/${servicioAnio}/${servicioTabla}`} className='btn btn-primary me-1'>NUEVO REGISTRO</NavLink>
                <NavLink to={`/servicios-genericos`} className='btn btn-primary me-1'>VOLVER</NavLink>
                <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
              </div>
              {
                listaAtenciones.length > 0
                  ?
                  listaAtenciones.map((atencion, index) => {
                    return (
                      <RegistroAtencion key={index} registro={atencion} servicioId={servicioId} anio={servicioAnio} tabla={servicioTabla} />
                    )
                  })
                  :
                  <AlertaListaSinResultados />
              }
            </>
            :
            <AlertaServiciosDesdeMovil />
        }
      </div>
    </PageLayout>
  )
}
