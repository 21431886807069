import { Spinner } from '../components/Spinner'
import ReactDOM from 'react-dom/client'

const showSpinner = () => {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')
  div.setAttribute('id', 'spinner-container')
  body.appendChild(div)
  const spinner = ReactDOM.createRoot(div)
  spinner.render(<Spinner />)
}

const hideSpinner = () => {
  const element = document.getElementById('spinner-container')
  element.parentNode.removeChild(element)
}

export const fetchData = async (url, withSpinner = true) => {
  if (window.navigator.onLine) {
    try {
      withSpinner && showSpinner()
      // console.log(url)
      const resp = await fetch(url)
      const data = await resp.json()
      // console.log(data)
      withSpinner && hideSpinner()
      return data
    } catch (error) {
      console.warn(error)
    }
  }
}