import { types } from '../types';

const initialValues = {
  listaServicios: [],
  servicio: {},
  serviciosActuales: [],
  serviciosGenericos: [],
  ultimoServicioActualModificado: 0,
  materialConsumido: [],
  materialSeleccionable: []
}

export const serviciosReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.listarServicios:
      return {
        ...state,
        listaServicios: action.payload.listaServicios
      }
    case types.verServicio:
      return {
        ...state,
        servicio: action.payload.servicio
      }
    case types.verServiciosActuales:
      return {
        ...state,
        serviciosActuales: action.payload.serviciosActuales,
        ultimoServicioActualModificado: action.payload.ultimoServicioActualModificado
      }
    case types.verServicioGenerico:
      return {
        ...state,
        serviciosGenericos: action.payload.serviciosGenericos
      }
    case types.listarMaterialConsumido:
      return {
        ...state,
        materialConsumido: action.payload.materialConsumido
      }
    case types.listarMaterialSeleccionable:
      return {
        ...state,
        materialSeleccionable: action.payload.materialSeleccionable
      }
    default:
      return state;
  }
}