import { types } from '../types';

const initialValues = {
  stockMaterialVehiculo: [],
  listaAlmacenes: []
}

export const stockMaterialReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.listarStockMaterialVehiculo:
      return {
        ...state,
        stockMaterialVehiculo: action.payload.stockMaterialVehiculo
      }
    case types.listarAlmacenes:
      return {
        ...state,
        listaAlmacenes: action.payload.listaAlmacenes
      }
    default:
      return state;
  }
}