import { types } from '../types'
import { fetchData } from '../fetchData'
import moment from 'moment'

export const solicitarListaCodigosActuacion = () => {
  const proceso = 'A_REGISTRO_ATENCIONES_CODIGOS_ACTUACION'
  const url = `${process.env.REACT_APP_APIVEL}${proceso}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { data !== '0' && dispatch(listarCodigosActuacion(data.LISTA_CODIGOS_ACTUACION)) }) })
}

export const solicitarListaAtenciones = (servicioId, servicioTabla, servicioAnio) => {
  const proceso = 'A_REGISTRO_ATENCIONES_VER'
  let params = `?IN_TABLA=${servicioTabla}`
  params = params + `&IN_ANIO=${servicioAnio}`
  params = params + `&IN_SERVICIO_ID=${servicioId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { data !== '0' && dispatch(listarAtenciones(data.LISTA_ATENCIONES)) }) })
}

export const nuevoRegistroDeAtencion = (values) => {
  const proceso = 'A_REGISTRO_ATENCIONES_NUEVA_ATENCION'
  let params = `?IN_TABLA=${values.servicioTabla}`
  params = params + `&IN_ANIO=${values.servicioAnio}`
  params = params + `&IN_SERVICIO_ID=${values.servicioId}`
  params = params + `&IN_CODIGO_ACTIVACION_ID=${values.codigoActuacion}`
  params = params + `&IN_ATENCION_DNI=${values.dni}`
  params = params + `&IN_ATENCION_DORSAL=${values.dorsal}`
  params = params + `&IN_ATENCION_EDAD=${values.edad}`
  params = params + `&IN_ATENCION_FECHA=${moment(values.fecha).format("DD/MM/YYYY")}`
  params = params + `&IN_ATENCION_HORA=${values.hora}:00`
  params = params + `&IN_ATENCION_INTERVINIENTES=${values.intervinientes}`
  params = params + `&IN_ATENCION_LICENCIA=${values.licencia ? 'SI' : 'NO'}`
  params = params + `&IN_ATENCION_NOMBRE_APELLIDOS=${values.nombreApellidos}`
  params = params + `&IN_ATENCION_NUMERO_INFORME_MEDICO=${values.informeMedicoNro}`
  params = params + `&IN_ATENCION_OBSERVACIONES=${values.observaciones}`
  params = params + `&IN_ATENCION_PROCEDENCIA=${values.lugar}`
  params = params + `&IN_ATENCION_TRASLADO=${values.traslado ? 'SI' : 'NO'}`
  params = params + `&IN_ATENCION_SEXO=${values.sexo}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { data !== '0' && dispatch(listarAtenciones(data.LISTA_ATENCIONES)) }) })
}

export const modificarRegistroDeAtencion = (values) => {
  const proceso = 'A_REGISTRO_ATENCIONES_MODIFICAR_ATENCION'
  let params = `?IN_TABLA=${values.servicioTabla}`
  params = params + `&IN_ANIO=${values.servicioAnio}`
  params = params + `&IN_SERVICIO_ID=${values.servicioId}`
  params = params + `&IN_ATENCION_ID=${values.atencionId}`
  params = params + `&IN_CODIGO_ACTIVACION_ID=${values.codigoActuacion}`
  params = params + `&IN_ATENCION_CODIGO_ACTUACION=${values.codigoActuacion}`
  params = params + `&IN_ATENCION_DNI=${values.dni}`
  params = params + `&IN_ATENCION_DORSAL=${values.dorsal}`
  params = params + `&IN_ATENCION_EDAD=${values.edad}`
  params = params + `&IN_ATENCION_FECHA=${moment(values.fecha).format("DD/MM/YYYY")}`
  params = params + `&IN_ATENCION_HORA=${values.hora}${values.hora.length < 6 ? ':00' : ''}`
  params = params + `&IN_ATENCION_INTERVINIENTES=${values.intervinientes}`
  params = params + `&IN_ATENCION_LICENCIA=${values.licencia ? 'SI' : 'NO'}`
  params = params + `&IN_ATENCION_NOMBRE_APELLIDOS=${values.nombreApellidos}`
  params = params + `&IN_ATENCION_NUMERO_INFORME_MEDICO=${values.informeMedicoNro}`
  params = params + `&IN_ATENCION_OBSERVACIONES=${values.observaciones}`
  params = params + `&IN_ATENCION_PROCEDENCIA=${values.lugar}`
  params = params + `&IN_ATENCION_TRASLADO=${values.traslado ? 'SI' : 'NO'}`
  params = params + `&IN_ATENCION_SEXO=${values.sexo}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { data !== '0' && dispatch(listarAtenciones(data.LISTA_ATENCIONES)) }) })
}

export const listarCodigosActuacion = (data) => {
  return {
    type: types.listarCodigosActuacion,
    payload: {
      codigosActuacion: data
    }
  }
}

export const listarAtenciones = (data) => {
  return {
    type: types.listarAtenciones,
    payload: {
      listaAtenciones: data
    }
  }
}