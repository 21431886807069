import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import 'animate.css'

export const ServicioIncidenciasForm = ({ resolve, reject, servicio }) => {

  const accept = (values) => {
    resolve(values)
    closeModal()
  }

  const cancel = () => {
    resolve('')
    closeModal()
  }

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  const formik = useFormik({
    initialValues: {
      observaciones: '',
      anio: servicio.IN_ANIO,
      idServicio: servicio.IN_SERVICIO_ID,
      tabla: servicio.TABLA,
    },
    validationSchema: Yup.object({
      observaciones: Yup.string().required()
    }),
    onSubmit: (values) => {
      accept(values)
    },
  })

  return (
    <div className='modal-card-container d-flex justify-content-center align-items-center'>
      <div className='card modal-card animate__animated animate__fadeIn'>
        <div className='card-header card-header-primary'>NUEVA INCIDENCIA</div>
        <div className='card-body'>
          <form id='informacionServicioForm' onSubmit={formik.handleSubmit}>
            <input
              type='hidden'
              name='anio'
              value={formik.values.anio}
              onChange={formik.handleChange}
            />
            <input
              type='hidden'
              name='idServicio'
              value={formik.values.idServicio}
              onChange={formik.handleChange}
            />
            <input
              type='hidden'
              name='tabla'
              value={formik.values.tabla}
              onChange={formik.handleChange}
            />

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='observaciones' className='form-label'>
                  Observaciones
                </label>
                <textarea
                  className={`form-control form-control-sm ${formik.touched.observaciones &&
                    formik.errors.observaciones &&
                    'form-control-error'
                    }`}
                  id='observaciones'
                  name='observaciones'
                  value={formik.values.observaciones}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  cols='30'
                  rows='10'
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div className='card-footer text-end'>
          <button className='btn btn-primary me-1' onClick={cancel}>CANCELAR</button>
          <button
            className='btn btn-primary'
            form='informacionServicioForm'
            type='submit'
          >ACEPTAR
          </button>
        </div>
      </div>
    </div>
  )
}