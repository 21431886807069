import { types } from '../types'
import { fetchData } from '../fetchData'

export const solicitudInicioComida = (inIndicativoServicioId, inCuadranteId) => {
  const proceso = 'A_COMIDA_INICIAR.PRO'
  let params = `?IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
  params = params + `&IN_CUADRANTE_ID=${inCuadranteId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => { data.then(data => { dispatch(gestionComida(data)) }) }
}

export const solicitudFinComida = (inIndicativoServicioId, inCuadranteId) => {
  const proceso = 'A_COMIDA_PARAR.PRO'
  let params = `?IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
  params = params + `&IN_CUADRANTE_ID=${inCuadranteId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => { data.then(data => { dispatch(gestionComida(data)) }) }
}

export const gestionComida = (data) => {
  return {
    type: types.gestionComida,
    payload: {
      comiendo: data.INICIO_COMIDA === 1 ? true : false,
      mensajeRespuestaPeticion: data.MOTIVO,
      mostrarMensaje: true,
      horaInicioComida: data.HORA_INICIO_COMIDA
    }
  }
}

export const resetearMensajeComida = () => {
  return {
    type: types.resetearMensajeComida,
    payload: {
      mensajeRespuestaPeticion: '',
      mostrarMensaje: false
    }
  }
}