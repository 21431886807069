import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebar } from '../redux/actions/sidebarActions'
import { NavLink, useNavigate } from 'react-router-dom'
import { Confirm } from '../helpers/Confirm'
import { SeleccionTipoColectivo } from '../helpers/SeleccionTipoColectivo'
import { obtenerListaCentrosDeDia } from '../redux/actions/centrosDeDiaActions'
import { FaHome, FaPhone, FaAmbulance, FaClipboardList, FaQuora, FaAngleRight, FaPencilAlt } from 'react-icons/fa'
import { obtenerServiciosActuales, obtenerServiciosCentroDeDia } from '../redux/actions/serviciosActions'


export const SideBarMenu = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { mostrarColectivo, esUrgencia, esServicioPreventivo, servicioMedicoEnfermeria } = useSelector(({ loginReducer }) => loginReducer)
  const { inIndicativoServicioId, inCuadranteId, permitirGestionServiciosTelefono } = useSelector(({ loginReducer }) => loginReducer)
  const { centrosDeDia } = useSelector(({ centrosDeDiaReducer }) => centrosDeDiaReducer)

  const orientation = window.screen.orientation.type
  const widthScreen = window.screen.width
  const heightScreen = window.screen.height

  let esPantallaTelefono = false

  if ((orientation === 'landscape-primary' && widthScreen < Number(750) && heightScreen < Number(370)) || (orientation === 'portrait-primary' && widthScreen < Number(370) && heightScreen < Number(750))) {
    esPantallaTelefono = true
  }

  useEffect(() => {
    document.querySelector('.sidebar-sub-menu').classList.remove('visible-sub-menu')
  })

  useEffect(() => {
    dispatch(obtenerListaCentrosDeDia())
  }, [dispatch])


  const closeSidebar = () => {
    document.getElementById('sidebar').classList.add('animate__slideOutLeft')
    setTimeout(() => {
      dispatch(toggleSidebar(false))
    }, 1000)
  }

  const handleSubMenu = (e) => {
    if (document.querySelector(`#sidebar-submenu-${e.currentTarget.id}`).classList.contains('visible-sub-menu')) {
      document.querySelector(`#sidebar-submenu-${e.currentTarget.id}`).classList.remove('visible-sub-menu')
    } else {
      if (document.querySelector(`#sidebar-submenu-${e.currentTarget.id}`).classList.contains('sub-sub-menu')) {
        document.querySelectorAll('.sub-sub-menu').forEach((item) => {
          item.classList.remove('visible-sub-menu')
        })
      } else {
        document.querySelectorAll('.sub-menu').forEach((item) => {
          item.classList.remove('visible-sub-menu')
        })
      }
      document.querySelector(`#sidebar-submenu-${e.target.id}`).classList.add('visible-sub-menu')
    }
  }

  const crearServicioUrgencia = () => {
    closeSidebar()
    setTimeout(async () => {
      const confirm = await Confirm('SE VA A CREAR UN NUEVO SERVICIO. ¿DESEA CONTINUAR?', 'ACEPTAR', 'CANCELAR')
      if (confirm) {
        dispatch(obtenerServiciosActuales(inIndicativoServicioId, inCuadranteId, 1))
        navigate(`/servicios-actuales/0`)
      }
    }, 100)
  }

  const crearServicioColectivo = () => {
    closeSidebar()
    setTimeout(async () => {
      const resp = await SeleccionTipoColectivo(centrosDeDia)
      if (resp.tipoColectivo === 'colectivoNormal') {
        dispatch(obtenerServiciosActuales(inIndicativoServicioId, inCuadranteId, 1))
        navigate('/servicios-actuales/0')
      }
      if (resp.tipoColectivo === 'centroDeDia') {
        const inCentroDeDiaId = resp.centroDeDia
        dispatch(obtenerServiciosCentroDeDia(inCuadranteId, inIndicativoServicioId, inCentroDeDiaId))
        navigate('/servicios-actuales/0')
      }
    }, 100);
  }

  return (
    <ul className='sidebar-menu'>
      <li className='sidebar-menu-item' id='sidebar-menu-home' onClick={closeSidebar}>
        <NavLink to='home'><FaHome /> INICIO</NavLink>
      </li>
      {/* {
        (!esPantallaTelefono || (esPantallaTelefono && permitirGestionServiciosTelefono))
        &&
        <> */}
      <li className='sidebar-menu-item' id='sidebar-menu-servicios' onClick={handleSubMenu}>
        <FaClipboardList /> SERVICIOS
      </li>
      <ul className='sidebar-sub-menu sub-menu hidden-sub-menu' id='sidebar-submenu-sidebar-menu-servicios'>
        {
          !servicioMedicoEnfermeria
          &&
          <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
            <NavLink to='/servicios-actuales/0'><FaAngleRight /> SERVICIOS ACTUALES</NavLink>
          </li>
        }
        <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
          <NavLink to='/servicios'><FaAngleRight /> SERVICIOS FINALIZADOS</NavLink>
        </li>
        {
          (esServicioPreventivo === 1)
          &&
          <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
            <NavLink to='/servicios-genericos'><FaAngleRight /> SERVICIOS PREVENTIVOS/EXTRAS</NavLink>
          </li>
        }
        {
          (mostrarColectivo === 1 && esUrgencia === 0)
          &&
          <li className='sidebar-sub-menu-item' onClick={crearServicioColectivo}>
            <FaAngleRight /> CREAR COLECTIVO / CENTRO DE DÍA
          </li>
        }
        {
          (mostrarColectivo === 0 && esUrgencia === 1)
          &&
          <li className='sidebar-sub-menu-item' onClick={crearServicioUrgencia}>
            <FaAngleRight /> CREAR SERVICIO URGENCIA
          </li>
        }
        {
          (esServicioPreventivo === 1 && !servicioMedicoEnfermeria)
          &&
          <li className='sidebar-sub-menu-item' onClick={crearServicioUrgencia}>
            <FaAngleRight /> CREAR TRASLADO DURANTE PREVENTIVO
          </li>
        }
      </ul>

      {
        !servicioMedicoEnfermeria
        &&
        <>
          <li className='sidebar-menu-item' id='sidebar-menu-vehiculos' onClick={handleSubMenu}>
            <FaAmbulance /> VEHICULOS
          </li>
          <ul className='sidebar-sub-menu sub-menu hidden-sub-menu' id='sidebar-submenu-sidebar-menu-vehiculos'>
            <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
              <NavLink to='repostajes'><FaAngleRight /> REPOSTAJES</NavLink>
            </li>
            <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
              <NavLink to='citas-taller'><FaAngleRight /> TALLER</NavLink>
            </li>
            {/* <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
              <NavLink to='material-vehiculo'><FaAngleRight /> STOCK MATERIAL</NavLink>
            </li> */}
            <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
              <NavLink to='solicitudes-reposicion-material'><FaAngleRight /> SOLICITUDES REPOSICIÓN MATERIAL</NavLink>
            </li>


            <li className='sidebar-sub-menu-item' id='sidebar-menu-oxigeno' onClick={handleSubMenu}>
              <FaAngleRight /> OXIGENO
              <ul className='sidebar-sub-menu sub-menu sub-sub-menu hidden-sub-menu' id='sidebar-submenu-sidebar-menu-oxigeno'>
                <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
                  <NavLink to='/recoger-oxigeno'><FaAngleRight /> RECOGER OXIGENO</NavLink>
                </li>
                <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
                  <NavLink to='/devolver-oxigeno'><FaAngleRight /> DEVOLVER OXIGENO</NavLink>
                </li>
              </ul>
            </li>

            <li className='sidebar-sub-menu-item' id='sidebar-menu-cambio-vehiculo' onClick={handleSubMenu}>
              <FaAngleRight /> CAMBIO VEHÍCULO
              <ul className='sidebar-sub-menu sub-menu sub-sub-menu hidden-sub-menu' id='sidebar-submenu-sidebar-menu-cambio-vehiculo'>
                <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
                  <NavLink to='/cambio-vehiculo'><FaAngleRight /> CAMBIO VEHICULO</NavLink>
                </li>
                <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
                  <NavLink to='/intercambio-vehiculo'><FaAngleRight /> INTERCAMBIO VEHICULO</NavLink>
                </li>
              </ul>
            </li>

            <li className='sidebar-sub-menu-item' onClick={closeSidebar}>
              <a href='https://gespark.ambulanciasdeasturias.com' target='_blank' rel="noreferrer"><FaAngleRight /> PARKING</a>
            </li>
          </ul>
          <li className='sidebar-menu-item' id='sidebar-menu-calidad' onClick={closeSidebar}>
            <NavLink to='/registros-calidad'><FaQuora /> CALIDAD</NavLink>
          </li>
          <li className='sidebar-menu-item' id='sidebar-menu-registros' onClick={closeSidebar}>
            <NavLink to='/registros'><FaPencilAlt /> REGISTROS</NavLink>
          </li>
        </>
      }
      <li className='sidebar-menu-item' id='sidebar-menu-contactos' onClick={closeSidebar}>
        <NavLink to='contactos'><FaPhone /> CONTACTOS</NavLink>
      </li>
    </ul>
  )
}
