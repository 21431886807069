import { types } from '../types'

const initialValues = {
  comiendo: false,
  mensajeRespuestaPeticion: '',
  mostrarMensaje: false,
  horaInicioComida: ''
}

export const comidaReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.gestionComida:
      return {
        ...state,
        comiendo: action.payload.comiendo,
        mensajeRespuestaPeticion: action.payload.mensajeRespuestaPeticion,
        mostrarMensaje: action.payload.mostrarMensaje,
        horaInicioComida: action.payload.horaInicioComida
      }
    case types.resetearMensajeComida:
      return {
        ...state,
        mensajeRespuestaPeticion: action.payload.mensajeRespuestaPeticion,
        mostrarMensaje: action.payload.mostrarMensaje
      }
    default:
      return state
  }
}