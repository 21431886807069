import { types } from '../types'
import { fetchData } from '../fetchData'

export const obtenerListaCitasTaller = (inVehiculoId) => {
  const proceso = 'A_CITAS_TALLER_VER.PRO'
  let params = `?IN_VEHICULO_ID=${inVehiculoId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { dispatch(listarCitasTaller(data.LISTA_CITASTALLER)) }) })
}

export const obtenerDatosCitaTaller = (citaTallerId) => {
  const proceso = 'A_CITA_TALLER_VER.PRO'
  let params = `?IN_CITATALLER_ID=${citaTallerId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { dispatch(citaTaller(data)) }) })
}

export const solicitarNuevoTrabajoCitaTaller = (citaTallerId, descripcionTrabajo, inConductorId) => {
  const proceso = 'A_CITA_TALLER_NUEVO_TRABAJO.PRO'
  let params = `?IN_CITATALLER_ID=${citaTallerId}`
  params = params + `&IN_TEXTO_NUEVO_TRABAJO=${descripcionTrabajo}`
  params = params + `&IN_CONDUCTOR_SOLICITA_ID=${inConductorId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { dispatch(nuevoTrabajoCitaTaller(data)) }) })
}

export const listarCitasTaller = (data) => {
  return {
    type: types.listarCitasTaller,
    payload: {
      listaCitasTaller: data
    }
  }
}

export const citaTaller = (data) => {
  const listaTrabajosSolicitadosCitaTaller = 'LISTA_TRABAJOS_SOLICITADOS' in data ? data.LISTA_TRABAJOS_SOLICITADOS : [];
  const listaTrabajosRealizadosCitaTaller = 'LISTA_TRABAJOS_REALIZADOS' in data ? data.LISTA_TRABAJOS_REALIZADOS : [];

  return {
    type: types.verCitaTaller,
    payload: {
      citaTaller: data,
      listaTrabajosSolicitadosCitaTaller: listaTrabajosSolicitadosCitaTaller,
      listaTrabajosRealizadosCitaTaller: listaTrabajosRealizadosCitaTaller
    }
  }
}

export const nuevoTrabajoCitaTaller = (data) => {
  const listaTrabajosSolicitadosCitaTaller = 'LISTA_TRABAJOS_SOLICITADOS' in data ? data.LISTA_TRABAJOS_SOLICITADOS : [];
  const listaTrabajosRealizadosCitaTaller = 'LISTA_TRABAJOS_REALIZADOS' in data ? data.LISTA_TRABAJOS_REALIZADOS : [];

  return {
    type: types.solicitarNuevoTrabajoCitaTaller,
    payload: {
      listaTrabajosSolicitadosCitaTaller: listaTrabajosSolicitadosCitaTaller,
      listaTrabajosRealizadosCitaTaller: listaTrabajosRealizadosCitaTaller
    }
  }
}