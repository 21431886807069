import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { marcarServicio, marcarServicioGenerico, informacionServicio, enviarNuevaIncidencia, marcarServicioUrgenciaComoSecundario } from '../redux/actions/serviciosActions'
import { ServicioInformacion } from '../helpers/ServicioInformacion'
import { ServicioConsumoMaterial } from '../helpers/ServicioConsumoMaterial'
import { ServicioFinalizar } from '../helpers/ServicioFinalizar'
import { ServicioIncidencias } from '../helpers/ServicioIncidencias'
import { ServicioFotos } from '../helpers/ServicioFotos'
import { FiMapPin } from 'react-icons/fi'
import uniqid from 'uniqid'

export const ServicioForm = ({ servicio }) => {
  const dispatch = useDispatch()
  const { inIndicativoServicioId, inCuadranteId } = useSelector(({ loginReducer }) => loginReducer)
  const inputsFormulario = servicio.INPUTS_FORMULARIO
  const gestionHoras = servicio.GESTION_HORAS
  const tipoServicioTxt = servicio.TIPO_SERVICIO_TXT
  const idServicio = servicio.IN_SERVICIO_ID
  const anioServicio = servicio.IN_ANIO

  const marcarHora = (e) => {
    const dataCode = e.target.getAttribute('data-code')
    document.getElementById(`${idServicio}-cargando-${dataCode}`).classList.remove('no-show')
    document.getElementById(`${idServicio}-marcar-${dataCode}`).classList.add('no-show')

    setTimeout(async () => {
      tipoServicioTxt === 'GENERICO'
        ?
        dispatch(marcarServicioGenerico(inCuadranteId, inIndicativoServicioId, idServicio, dataCode, anioServicio))
        :
        dispatch(marcarServicio(idServicio, dataCode, anioServicio))
      document.getElementById(`${idServicio}-cargando-${dataCode}`).classList.add('no-show')
      document.getElementById(`${idServicio}-marcar-${dataCode}`).classList.remove('no-show')
    }, 1000)

  }

  const disableButton = (
    (servicio.SERVICIO_ESTADO === 'FINALIZADO') || (servicio.SERVICIO_ESTADO === 'PENDIENTE FINALIZACION') || (servicio.SERVICIO_ESTADO === 'ANULADO')
      ?
      'disabled'
      :
      ''
  )

  const verInformacion = async () => {
    const resp = await ServicioInformacion({ servicio })
    if (resp !== '') {
      dispatch(informacionServicio(resp))
      if (resp.marcarComoSecundario) {
        dispatch(marcarServicioUrgenciaComoSecundario(servicio.TABLA, servicio.IN_ANIO, servicio.IN_SERVICIO_ID))
      }
    }
  }

  const verConsumoMaterial = async () => {
    const resp = await ServicioConsumoMaterial({ servicio })
    console.log(resp)
  }

  const finalizarServicio = async (e) => {
    const codigoFinalizacion = e.target.dataset.codigofinalizacion
    const resp = await ServicioFinalizar({ servicio }, codigoFinalizacion)
    console.log(resp)
  }

  const crearIncidencia = async () => {
    const resp = await ServicioIncidencias({ servicio })
    resp !== ''
      &&
      dispatch(enviarNuevaIncidencia(resp))
  }

  const fotosServicio = async () => {
    const resp = await ServicioFotos({ servicio })
  }

  const openMapOrigen = () => {
    const direccion = servicio.SERVICIO_ORIGEN.replace('DOMICILIO', '').trim()
    window.open(`geo:0,0?q=${direccion}`)
  }

  const openMapDestino = () => {
    const direccion = servicio.SERVICIO_DESTINO.replace('DOMICILIO', '').trim()
    window.open(`geo:0,0?q=${direccion}`)
  }


  return (
    <>
      <div className='mb-3 px-3'>
        <div className='d-flex mb-3'>
          <div>
            <div className='mb-1'>
              <strong>TIPO: </strong>
              <span>{servicio.TIPO_SERVICIO_TXT}</span>
            </div>
            <div className='mb-1'>
              <strong>FECHA: </strong>
              <span>{servicio.SERVICIO_FECHA_HORA_SERVICIO}</span>
            </div>
            <div className='mb-1'>
              <strong>PACIENTE: </strong>
              <span>{servicio.SERVICIO_PACIENTE}</span>
            </div>
            <div className='mb-1'>
              <strong>ORIGEN: </strong>
              <button className='btn btn-primary btn-sm btn-address' onClick={openMapOrigen}>{servicio.SERVICIO_ORIGEN} <FiMapPin className='align-baseline' /></button>
            </div>
            <div className='mb-1'>
              <strong>DESTINO: </strong>
              <button className='btn btn-primary btn-sm btn-address' onClick={openMapDestino}>{servicio.SERVICIO_DESTINO} <FiMapPin className='align-baseline' /></button>
            </div>
            <div className='mt-4'>
              <strong>OBSERVACIONES: </strong>
              <span>{servicio.SERVICIO_OBSERVACIONES}</span>
            </div>
          </div>
          {
            servicio.SERVICIO_POSICION &&
            <div className='ms-auto position-info'>
              <strong>SERVICIO: </strong>
              <span> {servicio.SERVICIO_POSICION}</span>
            </div>
          }
        </div>

        <div className='mt-4 mb-2'>
          <NavLink to='/servicios' className='btn btn-primary me-1 mb-2'>IR A SERVICIOS</NavLink>
          <button className='btn btn-primary me-1 mb-2' onClick={verInformacion}>INFORMACIÓN SERVICIO</button>
          <button className='btn btn-primary me-1 mb-2' onClick={verConsumoMaterial}>CONSUMO DE MATERIAL</button>
          <button className='btn btn-primary me-1 mb-2' onClick={crearIncidencia}>NUEVA INCIDENCIA</button>
          <button className='btn btn-primary me-1 mb-2' onClick={fotosServicio}>FOTOS SERVICIO</button>
          {
            servicio.TIPO_SERVICIO_ID === 38
            &&
            <NavLink to={`/servicio-generico-registros-atenciones/${servicio.IN_SERVICIO_ID}/${servicio.IN_ANIO}/${servicio.TABLA}`} className='btn btn-primary me-1 mb-2'>REGISTRO DE ATENCIONES</NavLink>
          }
        </div>
        {
          inputsFormulario.map(e => {
            return (
              e.VISIBLE &&
              <div key={uniqid()} className='card custom-card mt-3'>
                <div className='card-body py-2'>
                  <div className='d-flex d-flex justify-content-between py-2'>
                    <div className='servicios-titulo-marcas'>
                      <strong>{e.NOMBRE}</strong>
                    </div>
                    <div>
                      <strong>{e.VALOR}</strong>
                    </div>
                    <div>
                      {
                        (e.CODIGO_HORA === 900 || e.CODIGO_HORA === 1000 || e.CODIGO_HORA === 1100)
                          ?
                          <>
                            <button onClick={finalizarServicio} data-codigofinalizacion={e.CODIGO_HORA} className={`btn btn-primary btn-sm ${disableButton}`}>MARCAR</button>
                          </>
                          :
                          <>
                            <button className={`btn btn-primary btn-sm ${e.ESTADO_BOTON}`} id={`${idServicio}-marcar-${e.CODIGO_HORA}`} data-code={e.CODIGO_HORA} onClick={marcarHora}>MARCAR</button>
                            <button className='btn btn-primary btn-sm no-show' id={`${idServicio}-cargando-${e.CODIGO_HORA}`} type='button'>
                              <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
                              ENVIANDO...
                            </button>
                          </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        <div className='mt-3 text-center mb-3'>
          {
            gestionHoras.ANULAR === 1 && tipoServicioTxt !== 'GENERICO'
            &&
            <button onClick={finalizarServicio} data-codigofinalizacion='1000' className={`btn btn-primary me-1 ${disableButton}`}>ANULAR SERVICIO</button>
          }
          {
            gestionHoras.RESOLUCION_IN_SITU === 1 && tipoServicioTxt !== 'GENERICO'
            &&
            <button onClick={finalizarServicio} data-codigofinalizacion='1100' className={`btn btn-primary me-1 ${disableButton}`}>RESOLUCIÓN IN SITU</button>
          }
          {
            gestionHoras.FINALIZADO === 1 && tipoServicioTxt !== 'GENERICO'
            &&
            <button onClick={finalizarServicio} data-codigofinalizacion='900' className={`btn btn-primary ${disableButton}`}>FINALIZAR SERVICIO</button>
          }
        </div>
      </div>
    </>
  )
}
