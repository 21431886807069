import { types } from '../types'

const initialValues = {
  listaRepostajes: [],
  respuestaNuevoRepostaje: '',
  mensajeNuevoRepostaje: '',
  respuestaModificarRepostaje: '',
  mensajeModificarRepostaje: '',
  repostaje: {},
  listaAreas: [],
  listaGasolineras: [],
  listaTiposCombustible: []
}

export const repostajesReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.listarRepostajes:
      return {
        ...state,
        listaRepostajes: action.payload.listaRepostajes
      }
    case types.nuevoRepostaje:
      return {
        ...state,
        respuestaNuevoRepostaje: action.payload.respuestaNuevoRepostaje,
        mensajeNuevoRepostaje: action.payload.mensajeNuevoRepostaje
      }
    case types.verRepostaje:
      return {
        ...state,
        repostaje: action.payload.repostaje
      }
    case types.solicitudDatosNuevoRepostaje:
      return {
        ...state,
        listaAreas: action.payload.listaAreas,
        listaGasolineras: action.payload.listaGasolineras,
        listaTiposCombustible: action.payload.listaTiposCombustible
      }
    case types.modificarRepostaje:
      return {
        ...state,
        respuestaModificarRepostaje: action.payload.respuestaModificarRepostaje,
        mensajeModificarRepostaje: action.payload.mensajeModificarRepostaje
      }
    default:
      return state
  }
}