import React from 'react'
import { ChangeLog } from '../helpers/ChangeLog'
import moment from 'moment'

export const Footer = () => {

  const showChangeLog = () => {
    ChangeLog()
  }

  return (
    <div className='footer justify-content-center d-flex align-items-center'>
      <div>
        <span>&copy; {moment().format('YYYY')} - TRANSINSA | Ambulancias de Asturias | </span>
        <span onClick={showChangeLog}>{`versión ${process.env.REACT_APP_VERSION}`}</span>
        <span onClick={showChangeLog}>{window.location.host !== 'giaapp.ambulanciasdeasturias.com' && ' | versión desarrollo'}</span>
      </div>
    </div>
  )
}