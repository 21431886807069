import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
// import { checkLogin } from '../redux/actions/loginActions'
import { login } from '../redux/actions/loginActions'
import { fetchData } from '../redux/fetchData'
import logo512 from '../images/logo512.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import 'animate.css'
import { PageLayout } from '../components/PageLayout'
import { DosFactoresForm } from '../components/DosFactoresForm'

export const Login = () => {
  const dispatch = useDispatch()
  const [loginError, setLoginError] = useState(false)
  const [showTwoFactorInput, setShowTwoFactorInput] = useState(false)
  const [loginData, setLoginData] = useState({})
  const [accesoMedicosEnfermeria, setAccesoMedicosEnfermeria] = useState(false)

  useEffect(() => {
    if (loginError) {
      document.getElementById('login-alert-error').classList.remove('no-visible')
      document.getElementById('login-alert-error').classList.add('animate__fadeIn')
    }
  }, [loginError])

  useEffect(() => {
    loginData.SERVICIO_MEDICO_ENFERMERIA = accesoMedicosEnfermeria
    if (loginData?.HACER_DOBLE_FACTOR) {
      loginData.HACER_DOBLE_FACTOR === '1'
        ?
        twoFactorCode(loginData)
        :
        dispatch(login(loginData))
    }
  }, [dispatch, loginData])

  const twoFactorCode = (data) => {
    setShowTwoFactorInput(true)
  }

  const formik = useFormik({
    initialValues: {
      indicativoServicio: '',
      indicativoMedico: '',
      indicativoDue: '',
      indicativoConductor: '',
      indicativoCamillero: '',
      matriculaVehiculo: '',
      kilometrosIniciales: '',
      dispositivoContacto: ''
    },
    validationSchema: Yup.object(
      accesoMedicosEnfermeria
        ?
        {
          indicativoServicio: Yup.string().required('Campo requerido'),
          dispositivoContacto: Yup.string().required('Campo requerido'),
        }
        :
        {
          indicativoServicio: Yup.string().required('Campo requerido'),
          indicativoConductor: Yup.string().required('Campo requerido'),
          matriculaVehiculo: Yup.string().required('Campo requerido'),
          dispositivoContacto: Yup.string().required('Campo requerido'),
        }
    ),
    onSubmit: values => {
      const checkLogin = (values) => {
        const proceso = 'A_COMPRUEBA_INICIO_JORNADA.PRO'
        let params = `?IN_INDICATIVO_SERVICIO_TXT=${values.indicativoServicio}`
        params = params + `&IN_INDICATIVO_MEDICO_TXT=${values.indicativoMedico}`
        params = params + `&IN_INDICATIVO_DUE_TXT=${values.indicativoDue}`
        params = params + `&IN_INDICATIVO_CONDUCTOR_TXT=${values.indicativoConductor}`
        params = params + `&IN_INDICATIVO_CAMILLERO_TXT=${values.indicativoCamillero}`
        params = params + `&IN_VEHICULO_MATRICULA_TXT=${values.matriculaVehiculo}`
        params = params + `&IN_VEHICULO_KMS_TXT=${values.kilometrosIniciales}`
        params = params + `&IN_DISPOSITIVO_TXT=${values.dispositivoContacto}`
        const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
        const data = fetchData(url)
        data.then(data => data === 0 ? setLoginError(true) : setLoginData(data))
      }
      checkLogin(values)
    },
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    formik.setFieldValue(name, value.toUpperCase())
  }

  return (
    <PageLayout testCuadrante={false}>
      <div className='content d-flex justify-content-center align-items-center'>
        <div className='card animate__animated animate__fadeIn' id='card-login'>
          <div className='card-header text-center bg-primary p-4'>
            <img src={logo512} alt='Logo Transinsa | Ambulancias de Asturias' className='card-img-top img-fluid' id='login-img' />
            <h3 className='text-light mt-3 mb-3'>INICIO DE JORNADA</h3>
          </div>
          <div className='card-body p-4' style={{ minHeight: 632, maxWidth: 480 }}>
            {
              showTwoFactorInput
                ?
                <DosFactoresForm data={loginData} />
                :
                <form onSubmit={formik.handleSubmit}>

                  <div className='form-check form-switch mb-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id='acceso-medicos-enfermeria'
                      checked={accesoMedicosEnfermeria}
                      onChange={() => { setAccesoMedicosEnfermeria(!accesoMedicosEnfermeria) }}
                    />
                    <label className={`fomr-check-label ${accesoMedicosEnfermeria ? 'text-primary' : 'text-black-50'}`} htmlFor='acceso-medicos-enfermeria'>Acceso Médicos y Enfermería</label>
                  </div>

                  <div className='mb-1'>
                    <label htmlFor='indicativoServicio' className='form-label'>INDICATIVO DE SERVICIO</label>
                    <input
                      type='text'
                      className={`form-control form-control ${formik.touched.indicativoServicio && formik.errors.indicativoServicio && 'form-control-error'}`}
                      id='indicativoServicio'
                      name='indicativoServicio'
                      value={formik.values.indicativoServicio}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  {
                    accesoMedicosEnfermeria
                    &&
                    <>
                      <div className='mb-1'>
                        <label htmlFor='indicativoMedico' className='form-label'>INDICATIVO MÉDICO</label>
                        <input
                          type='text'
                          className={`form-control form-control ${formik.touched.indicativoMedico && formik.errors.indicativoMedico && 'form-control-error'}`}
                          id='indicativoMedico'
                          name='indicativoMedico'
                          value={formik.values.indicativoMedico}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <div className='mb-1'>
                        <label htmlFor='indicativoDue' className='form-label'>INDICATIVO DUE</label>
                        <input
                          type='text'
                          className={`form-control form-control ${formik.touched.indicativoDue && formik.errors.indicativoDue && 'form-control-error'}`}
                          id='indicativoDue'
                          name='indicativoDue'
                          value={formik.values.indicativoDue}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </>
                  }

                  {
                    !accesoMedicosEnfermeria
                    &&
                    <>
                      <div className='mb-1'>
                        <label htmlFor='indicativoConductor' className='form-label'>INDICATIVO DE CONDUCTOR</label>
                        <input
                          type='text'
                          className={`form-control form-control ${formik.touched.indicativoConductor && formik.errors.indicativoConductor && 'form-control-error'}`}
                          id='indicativoConductor'
                          name='indicativoConductor'
                          value={formik.values.indicativoConductor}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <div className='mb-1'>
                        <label htmlFor='indicativoCamillero' className='form-label'>INDICATIVO DE CAMILLERO</label>
                        <input
                          type='text'
                          className={`form-control form-control ${formik.touched.indicativoCamillero && formik.errors.indicativoCamillero && 'form-control-error'}`}
                          id='indicativoCamillero'
                          name='indicativoCamillero'
                          value={formik.values.indicativoCamillero}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <div className='mb-1'>
                        <label htmlFor='matriculaVehiculo' className='form-label'>MATRICULA DEL VEHICULO</label>
                        <input
                          type='text'
                          className={`form-control form-control ${formik.touched.matriculaVehiculo && formik.errors.matriculaVehiculo && 'form-control-error'}`}
                          id='matriculaVehiculo'
                          name='matriculaVehiculo'
                          value={formik.values.matriculaVehiculo}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <div className='mb-1'>
                        <label htmlFor='kilometrosIniciales' className='form-label'>KILOMETROS INICIALES</label>
                        <input
                          type='text'
                          className={`form-control form-control ${formik.touched.kilometrosIniciales && formik.errors.kilometrosIniciales && 'form-control-error'}`}
                          id='kilometrosIniciales'
                          name='kilometrosIniciales'
                          value={formik.values.kilometrosIniciales}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </>
                  }

                  <div className='mb-1'>
                    <label htmlFor='dispositivoContacto' className='form-label'>DISPOSITIVO DE CONTACTO</label>
                    <input
                      type='text'
                      className={`form-control form-control ${formik.touched.dispositivoContacto && formik.errors.dispositivoContacto && 'form-control-error'}`}
                      id='dispositivoContacto'
                      name='dispositivoContacto'
                      value={formik.values.dispositivoContacto}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>

                  <div className='alert alert-danger mt-3 animate__animated no-visible text-center' id='login-alert-error'>
                    INICIO DE SESION INCORRECTO
                  </div>

                  <div className='my-3 text-center'>
                    <button type='submit' className='btn btn-primary'>INICIAR JORNADA</button>
                  </div>

                </form>
            }

          </div>
        </div>
      </div>
    </PageLayout>
  )
}