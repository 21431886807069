import React from 'react'

export const Contacto = ({ contacto }) => {

  let esPantallaTelefono = false

  const orientation = window.screen.orientation.type
  const widthScreen = window.screen.width
  const heightScreen = window.screen.height

  if ((orientation === 'landscape-primary' && widthScreen < Number(750) && heightScreen < Number(370)) || (orientation === 'portrait-primary' && widthScreen < Number(370) && heightScreen < Number(750))) {
    esPantallaTelefono = true
  }

  return (
    <li className='list-group-item list-group-item-action py-3'>
      <a href={esPantallaTelefono ? `tel:${contacto.TELEFONO}` : console.log('try phone call')}>
        <div className='d-flex justify-content-between'>
          <div className='fw-bold'>{contacto.INDICATIVO_SERVICIO}</div>
          <div className='badge bg-primary'>{contacto.TELEFONO}</div>
        </div>
      </a>
    </li>
  )
}
