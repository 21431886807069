import { types } from '../types'
import { fetchData } from '../fetchData'

export const obtenerListaContactos = () => {
  const proceso = 'A_VER_LISTIN_TELEFONICO.PRO'
  const url = `${process.env.REACT_APP_APIVEL}${proceso}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => {
      dispatch(cargarListaContactos(data.LISTA_TELEFONOS))
    })
  }
}

export const cargarListaContactos = (data) => {
  return {
    type: types.cargarListaContactos,
    payload: {
      contactos: data
    }
  }
}