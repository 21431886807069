import React from 'react'
import { FiUser } from 'react-icons/fi'
import { UserInfo } from './UserInfo'

export const UserInfoButton = () => {

  const toggleUserInfo = () => {
    document.getElementById('userinfo-card').classList.toggle('no-show')
  }

  return (
    <>
      <div className='text-end me-3'>
        <UserInfo />
      </div>
      <button type='button' className='btn btn-outline-light'>
        <span className='align-middle'></span><FiUser className='align-middle' onClick={toggleUserInfo} />
      </button>
    </>
  )
}
