import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { enviarDatosRecogerOxigeno } from '../redux/actions/oxigenoActions'
import { NavLink, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import { Confirm } from '../helpers/Confirm'
import { Scanner } from '../helpers/Scanner'
import { IoIosBarcode } from 'react-icons/io'

export const OxigenoRecogerForm = ({ listaAlmacenesOxigeno, inConductorId, inIndicativoServicioId, inVehiculoId, respuestaRecogerOxigeno }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      inConductorId: inConductorId,
      inIndicativoServicioId: inIndicativoServicioId,
      inVehiculoId: inVehiculoId,
      almacenOxigeno: '',
      codigoBotella: '',
      fechaCaducidad: moment().format('YYYY-MM-DD')
    },
    validationSchema: Yup.object(
      {
        almacenOxigeno: Yup.string().required('Campo requerido.'),
        codigoBotella: Yup.string().required('Campo requerido.'),
        fechaCaducidad: Yup.date().required('Campo requerido').min(moment().format('YYYY-MM-DD'), 'Fecha incorrecta.')
      }
    ),
    onSubmit: values => {
      setTimeout(async () => {
        const resp = await Confirm('SE VA A REGISTRAR UNA RECOGIDA DE OXIGENO. ¿DESEA CONTNUAR?', 'ACEPTAR', 'CANCELAR')
        if (resp) {
          dispatch(enviarDatosRecogerOxigeno(values))
        }
      }, 100)
    },
  })

  useEffect(() => {
    respuestaRecogerOxigeno === '1'
      ?
      setTimeout(async () => {
        const resp = await Confirm('RECOGIDA DE OXIGENO REALIZADA CORRECTAMENTE.', 'ACEPTAR')
        if (resp) {
          navigate('/')
        }
      }, 100)
      :
      (respuestaRecogerOxigeno === '0')
      &&
      document.querySelector('#alert').classList.remove('no-show')
  }, [respuestaRecogerOxigeno, navigate, dispatch])

  const getScanner = async () => {
    const barcode = await Scanner()
    document.getElementById('codigoBotella').setAttribute('value', barcode)
  }

  return (
    <>
      <div className='mb-3 px-3'>
        <form onSubmit={formik.handleSubmit} id='recogerOxigeno'>

          <input type='hidden' name='inConductorId' id='inConductorId' />
          <input type='hidden' name='inIndicativoServicioId' id='inIndicativoServiocioId' />
          <input type='hidden' name='inVehiculoId' id='inVehiculoId' />

          <div className='row mb-3'>
            <div className='col'>
              <label htmlFor='almacenOxigeno' className='form-label'>Almacén oxígeno</label>
              <select
                className={`form-select ${(formik.touched.almacenOxigeno && formik.errors.almacenOxigeno) && 'form-control-error'}`}
                id='almacenOxigeno'
                name='almacenOxigeno'
                value={formik.values.almacenOxigeno}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value=''>Seleccione un almacén...</option>
                {
                  listaAlmacenesOxigeno.LISTA_ALMACENES.map(almacenOxigeno => {
                    return (
                      <option key={almacenOxigeno.IN_ALMACEN_ID} value={almacenOxigeno.IN_ALMACEN_ID}>{almacenOxigeno.NOMBRE_ALMACEN}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-sm-6'>
              <label htmlFor='codigoBotella' className='form-label'>Código de botella</label>
              <div className='input-group mb-3'>
                <input
                  className={`form-control form-control-sm ${(formik.touched.codigoBotella && formik.errors.codigoBotella) && 'form-control-error'}`}
                  type='text'
                  name='codigoBotella'
                  id='codigoBotella'
                  value={formik.values.codigoBotella}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <button className='btn btn-outline-primary' type='button' onClick={getScanner}><IoIosBarcode /></button>
              </div>
            </div>
            <div className='col-sm-6'>
              <label htmlFor='fechaCaducidad' className='form-label'>Fecha caducidad</label>
              <input
                type='date'
                className={`form-control form-control-sm ${(formik.touched.fechaCaducidad && formik.errors.fechaCaducidad) && 'form-control-error'}`}
                id='fechaCaducidad'
                name='fechaCaducidad'
                value={formik.values.fechaCaducidad}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

        </form>
      </div>
      <div className='text-end mt-auto pt-3 px-3'>
        <div className='alert alert-primary text-center no-show' id='alert' role='alert'>
          AH OCURRIDO UN PROBLEMA. REVISE LOS DATOS INTRODUCIDOS Y VUELVA A INTENTARLO.
        </div>
        <NavLink to='/' className='btn btn-primary me-1'>CANCELAR</NavLink>
        <button type='submit' form='recogerOxigeno' className='btn btn-primary'>ACEPTAR</button>
      </div>
    </>
  )
}
