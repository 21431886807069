import { types } from '../types'
import { fetchData } from '../fetchData'

export const enviarDatosSolicitudCambioVehiculo = ({ vehiculoMatricula, vehiculoNuevoMatricula, vehiculoNuevoKms }) => {
  const proceso = 'A_CREA_CAMBIO_VEHICULO.PRO'
  let params = `?IN_VEHICULO_ACTUAL_MATRICULA=${vehiculoMatricula}`
  params = params + `&IN_VEHICULO_NUEVO_MATRICULA=${vehiculoNuevoMatricula}`
  params = params + `&IN_VEHICULO_NUEVO_KMS=${vehiculoNuevoKms}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => {
      if (data.ERROR === '0') {
        dispatch(actualizarDatosVehiculo(data))
      }
      dispatch(cambioVehiculo(data))
    })
  }
}


export const enviarDatosSolicitudIntercambioVehiculo = ({ vehiculoMatricula, vehiculoKms, vehiculoNuevoMatricula, vehiculoNuevoKms }) => {
  const proceso = 'A_CREA_INTERCAMBIO_VEHICULO.PRO'
  let params = `?IN_VEHICULO_ACTUAL_MATRICULA=${vehiculoMatricula}`
  params = params + `&IN_VEHICULO_ACTUAL_KMS=${vehiculoKms}`
  params = params + `&IN_VEHICULO_NUEVO_MATRICULA=${vehiculoNuevoMatricula}`
  params = params + `&IN_VEHICULO_NUEVO_KMS=${vehiculoNuevoKms}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => {
      if (data.ERROR === '0') {
        dispatch(actualizarDatosVehiculo(data))
      }
      dispatch(intercambioVehiculo(data))
    })
  }
}

export const cambioVehiculo = (data) => {
  return {
    type: types.cambioVehiculo,
    payload: {
      respuestaCambioVehiculo: data.ERROR,
      mensajeCambioVehiculo: data.MENSAJE_ERROR
    }
  }
}

export const intercambioVehiculo = (data) => {
  return {
    type: types.intercambioVehiculo,
    payload: {
      respuestaIntercambioVehiculo: data.ERROR,
      mensajeIntercambioVehiculo: data.MENSAJE_ERROR
    }
  }
}

export const actualizarDatosVehiculo = (data) => {
  return {
    type: types.actualizarDatosVehiculo,
    payload: {
      vehiculoMatricula: data.VEHICULO_NUEVO_MATRICULA,
      inVehiculoId: data.VEHICULO_NUEVO_ID,
      inCuadranteId: data.CUADRANTE_NUEVO_ID,
      mensajeAvisos: data.MENSAJE_AVISOS
    }
  }
}