import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../redux/store'
import { ServicioConsumoMaterialForm } from '../components/ServicioConsumoMaterialForm'

export const ServicioConsumoMaterialModal = ({ resolve, reject, servicio }) => {
  return (
    <Provider store={store}>
      <ServicioConsumoMaterialForm
        resolve={resolve}
        reject={reject}
        servicio={servicio}
      />
    </Provider>
  )
}
