import { types } from '../types'

const initialValues = {
  respuestaCambioVehiculo: '',
  mensajeCambioVehiculo: '',
  respuestaIntercambioVehiculo: '',
  mensajeIntercambioVehiculo: ''
}

export const cambioVehiculoReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.cambioVehiculo:
      return {
        ...state,
        respuestaCambioVehiculo: action.payload.respuestaCambioVehiculo,
        mensajeCambioVehiculo: action.payload.mensajeCambioVehiculo
      }
    case types.intercambioVehiculo:
      return {
        ...state,
        respuestaIntercambioVehiculo: action.payload.respuestaIntercambioVehiculo,
        mensajeIntercambioVehiculo: action.payload.mensajeIntercambioVehiculo
      }
    default:
      return state
  }
}