import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleSidebar } from '../redux/actions/sidebarActions'
import { FiMenu } from 'react-icons/fi'
import { useSelector } from 'react-redux'

export const SideBarOpenButton = () => {
  const dispatch = useDispatch()
  const { pedirFotosInicioJornada } = useSelector(({ loginReducer }) => loginReducer)
  const openSideBar = () => {
    dispatch(toggleSidebar(true))
  }

  return (
    <div className='me-auto'>
      {
        !pedirFotosInicioJornada
        &&
        <FiMenu className='sidebar-open-button' onClick={openSideBar} />
      }
    </div>
  )
}
