import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { PageLayout } from '../components/PageLayout'
import { obtenerListaRepostajes } from '../redux/actions/repostajesActions'
import { TitlePage } from '../components/TitlePage'
import { AlertaListaSinResultados } from '../components/AlertaListaSinResultados'
import { Repostaje } from '../components/Repostaje'

export const Repostajes = () => {
  const dispatch = useDispatch()
  const { inVehiculoId } = useSelector(({ loginReducer }) => loginReducer)
  const { listaRepostajes } = useSelector(({ repostajesReducer }) => repostajesReducer)

  useEffect(() => {
    dispatch(obtenerListaRepostajes(inVehiculoId))
  }, [dispatch, inVehiculoId])

  return (
    <PageLayout>
      <div className='content'>
        <TitlePage title='REPOSTAJES' />
        <div className='text-end mb-3 px-3'>
          <NavLink to='/nuevo-repostaje' className='btn btn-primary me-1'>NUEVO REPOSTAJE</NavLink>
          <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
        </div>
        {
          listaRepostajes.length > 0
            ?
            listaRepostajes.map(repostaje => {
              return (
                <Repostaje key={repostaje.IN_REPOSTAJE_ID} repostaje={repostaje} />
              )
            })
            :
            <AlertaListaSinResultados />
        }
      </div>
    </PageLayout>
  )
}