import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { obtenerDatosRegistroCalidad, verRegistroCalidad } from '../redux/actions/calidadActions'
import { CalidadTipoConcepto } from '../components/CalidadTipoConcepto'
import { CalidadActualizarKmsProximoMantenimiento } from '../components/CalidadActualizarKmsProximoMantenimiento'

export const CalidadRegistroVer = () => {
  const dispatch = useDispatch()
  const { registroCalidadId } = useParams()
  const { registroCalidad } = useSelector(({ calidadReducer }) => calidadReducer)

  useEffect(() => {
    dispatch(obtenerDatosRegistroCalidad(registroCalidadId))
    return () => {
      dispatch(verRegistroCalidad({}))
    }
  }, [dispatch, registroCalidadId])

  const arriba = () => {
    window.scrollTo(0, 0)
  }

  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title='REGISTRO DE CALIDAD' />
        <div className='mb-3 px-3'>
          {
            Object.keys(registroCalidad).length > 0
            &&
            <>
              <div className='row mb-3'>
                <div className='col'>
                  <label htmlFor='registro' className='form-label'>Registro</label>
                  <span className='form-control form-control-sm' id='registro'>
                    {registroCalidad.REGISTRO_CALIDAD_NAME}
                  </span>
                </div>
              </div>

              <div className='row mb-5'>
                <div className='col-sm-3'>
                  <label htmlFor='fecha' className='form-label'>Fecha</label>
                  <span className='form-control form-control-sm' id='fecha'>
                    {registroCalidad.FECHAHORA_REGISTRO.slice(0, 10)}
                  </span>
                </div>
                <div className='col-sm-2'>
                  <label htmlFor='areaSanitaria' className='form-label'>Hora</label>
                  <span className='form-control form-control-sm'>
                    {registroCalidad.FECHAHORA_REGISTRO.slice(11)}
                  </span>
                </div>
                <div className='col-sm-4'>
                  <CalidadActualizarKmsProximoMantenimiento kmsProximoMantenimiento={registroCalidad.KMS_PROXIMO_MANTENIMIENTO} registroCalidadId={registroCalidad.IN_REGISTRO_CALIDAD_ID} />
                </div>
              </div>

              <div className='accordion' id='accordionTipoConceptoCalidad'>
                {
                  registroCalidad.TIPOS_CONCEPTOS.map(tipoConcepto => {
                    const nombreTipoConcepto = tipoConcepto.TIPO_CONCEPTO_NAME
                    const preguntas = tipoConcepto.LISTA_PREGUNTAS
                    return (
                      <CalidadTipoConcepto key={tipoConcepto.TIPO_CONCEPTO_ID} arriba={arriba} idTipoConcepto={tipoConcepto.TIPO_CONCEPTO_ID} nombreTipoConcepto={nombreTipoConcepto} registroCalidadId={registroCalidad.IN_REGISTRO_CALIDAD_ID} preguntas={preguntas} />
                    )
                  })
                }
              </div>
            </>
          }
        </div>
        <div className='text-end mt-auto pt-3'>
          <NavLink to='/registros-calidad' className='btn btn-primary me-1'>VOLVER</NavLink>
        </div>
      </div>
    </PageLayout>
  )
}
