import Resizer from 'react-image-file-resizer'

// https://www.npmjs.com/package/react-image-file-resizer
export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1920,
      1080,
      "JPEG",
      80,
      0,
      (uri) => { resolve(uri) },
      "base64"
    )
    // let base64string = ''
    // let reader = new FileReader()
    // reader.readAsDataURL(file)
    // reader.onload = () => {
    //   base64string = reader.result
    //   resolve(base64string)
    // }
  })
}
