import React, { useEffect, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { obtenerServiciosActuales, verServiciosActuales } from '../redux/actions/serviciosActions'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { AlertaListaSinResultados } from '../components/AlertaListaSinResultados'
import { ServicioForm } from '../components/ServicioForm'
import moment from 'moment/moment'
import { AlertaServiciosDesdeMovil } from '../components/AlertaServiciosDesdeMovil'

export const ServiciosActuales = () => {
  const dispatch = useDispatch()
  const { idServicioActivo } = useParams()
  const { inIndicativoServicioId, inCuadranteId, permitirGestionServiciosTelefono } = useSelector(({ loginReducer }) => loginReducer)
  const { serviciosActuales } = useSelector(({ serviciosReducer }) => serviciosReducer)

  let servicioPosicion = 0;

  const orientation = window.screen.orientation.type
  const widthScreen = window.screen.width
  const heightScreen = window.screen.height

  let esPantallaTelefono = false

  if ((orientation === 'landscape-primary' && widthScreen < Number(750) && heightScreen < Number(370)) || (orientation === 'portrait-primary' && widthScreen < Number(370) && heightScreen < Number(750))) {
    esPantallaTelefono = true
  }

  useEffect(() => {
    dispatch(obtenerServiciosActuales(inIndicativoServicioId, inCuadranteId))
    return () => {
      dispatch(verServiciosActuales([]))
    }
  }, [dispatch, inIndicativoServicioId, inCuadranteId])

  useLayoutEffect(() => {
    const activarServicioVisualizar = () => {
      document.querySelectorAll('.carousel-item').forEach(element => {
        element.classList.remove('active')
      })
      idServicioActivo === '0' && serviciosActuales.length > 0
        ?
        document.querySelectorAll('.carousel-item')[0].classList.add('active')
        :
        document.getElementById(idServicioActivo).classList.add('active')
    }
    document.querySelectorAll('.carousel-item').length > 0
      &&
      activarServicioVisualizar()
  }, [serviciosActuales, idServicioActivo])

  const getButtonState = (dateTimeText, estadoServicio) => {
    if ((estadoServicio === 'FINALIZADO') || (estadoServicio === 'PENDIENTE FINALIZACION') || (estadoServicio === 'ANULADO') || ((dateTimeText !== '') && (moment(dateTimeText, 'DD-MM-YYYY hh:mm').isSameOrAfter(moment('01-01-2000 00:00', 'DD-MM-YYYY hh:mm'))))) {
      return 'disabled'
    } else {
      return ''
    }
  }

  const getDateTimeText = (dateTimeText) => {
    if ((dateTimeText === '') || (moment(dateTimeText, 'DD-MM-YYYY hh:mm').isBefore(moment('01-01-2000 00:00', 'DD-MM-YYYY hh:mm')))) {
      return ('--/--/---- --:--')
    } else {
      return (dateTimeText)
    }
  }

  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title={`SERVICIOS ASIGNADOS: ${serviciosActuales.length}`} />
        {/* {
          !esPantallaTelefono || (esPantallaTelefono && permitirGestionServiciosTelefono)
            ?
            <> */}
        <div className="d-flex justify-content-between px-3">
          <div>
            {
              serviciosActuales.length > 1 &&
              <button className="btn btn-primary" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="">ANTERIOR</span>
              </button>
            }
          </div>
          <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
          <div>
            {serviciosActuales.length > 1 &&
              <button className="btn btn-primary" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="">SIGUIENTE</span>
              </button>
            }
          </div>
        </div>
        <hr />
        <div className='carousel slide px-3' id="carouselExampleControls" data-bs-interval="false">
          <div className='carousel-inner'>
            {
              serviciosActuales.length > 0
                ?
                serviciosActuales.map(servicio => {
                  return (
                    <div key={servicio.IN_SERVICIO_ID} className='carousel-item' id={servicio.IN_SERVICIO_ID}>
                      <ServicioForm
                        servicio={
                          {
                            "SERVICIO_POSICION": `${servicioPosicion += 1}/${serviciosActuales.length}`,
                            "GESTION_FIN_SERVICIO": {
                              "CODIGO_FINALIZACION": servicio.GESTION_FIN_SERVICIO.CODIGO_FINALIZACION,
                              "KMS_RECORRIDOS": servicio.GESTION_FIN_SERVICIO.KMS_RECORRIDOS,
                              "PACIENTES_C_SILLA": servicio.GESTION_FIN_SERVICIO.PACIENTES_C_SILLA,
                              "PACIENTES_SIN_SILLA": servicio.GESTION_FIN_SERVICIO.PACIENTES_SIN_SILLA,
                            },
                            "GESTION_HORAS": {
                              "ANULAR": servicio.GESTION_HORAS.ANULAR,
                              "FINALIZADO": servicio.GESTION_HORAS.FINALIZADO,
                              "HORA_ACTIVACION": servicio.GESTION_HORAS.HORA_ACTIVACION,
                              "HORA_SERVICIO": servicio.GESTION_HORAS.HORA_SERVICIO,
                              "INICIO_SERVICIO": servicio.GESTION_HORAS.INICIO_SERVICIO,
                              "LIBRE_RETORNANDO": servicio.GESTION_HORAS.LIBRE_RETORNANDO,
                              "LLEGADA_DESTINO": servicio.GESTION_HORAS.LLEGADA_DESTINO,
                              "LLEGADA_LUGAR": servicio.GESTION_HORAS.LLEGADA_LUGAR,
                              "REALIZANDOSE": servicio.GESTION_HORAS.REALIZANDOSE,
                              "RESOLUCION_IN_SITU": servicio.GESTION_HORAS.RESOLUCION_IN_SITU,
                              "SALIDA_LUGAR": servicio.GESTION_HORAS.SALIDA_LUGAR,
                            },
                            "IN_ANIO": servicio.IN_ANIO,
                            "IN_SERVICIO_ID": servicio.IN_SERVICIO_ID,
                            "SERVICIO_DESTINO": servicio.SERVICIO_DESTINO,
                            "SERVICIO_ESTADO": servicio.SERVICIO_ESTADO,
                            "SERVICIO_ES_URGENCIA": servicio.SERVICIO_ES_URGENCIA,
                            "INPUTS_FORMULARIO": [
                              {
                                "POSICION": 1,
                                "VISIBLE": servicio.GESTION_HORAS.INICIO_SERVICIO === 1 ? true : false,
                                "NOMBRE": "INICIO SERVICIO",
                                "VALOR": getDateTimeText(servicio.SERVICIO_FECHAHORA_INICIO),
                                "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHAHORA_INICIO, servicio.SERVICIO_ESTADO),
                                "MOSRTAR_BOTON": true,
                                "CODIGO_HORA": 300
                              },
                              {
                                "POSICION": 2,
                                "VISIBLE": servicio.GESTION_HORAS.REALIZANDOSE === 1 ? true : false,
                                "NOMBRE": "REALIZANDOSE",
                                "VALOR": getDateTimeText(servicio.SERVICIO_FECHAHORA_REALIZANDOSE),
                                "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHAHORA_REALIZANDOSE, servicio.SERVICIO_ESTADO),
                                "MOSRTAR_BOTON": true,
                                "CODIGO_HORA": 400
                              },
                              {
                                "POSICION": 3,
                                "VISIBLE": servicio.GESTION_HORAS.LLEGADA_LUGAR === 1 ? true : false,
                                "NOMBRE": "LLEGADA LUGAR",
                                "VALOR": getDateTimeText(servicio.SERVICIO_FECHAHORA_LLEGADA_LUGAR),
                                "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHAHORA_LLEGADA_LUGAR, servicio.SERVICIO_ESTADO),
                                "MOSRTAR_BOTON": true,
                                "CODIGO_HORA": 500
                              },
                              {
                                "POSICION": 4,
                                "VISIBLE": servicio.GESTION_HORAS.SALIDA_LUGAR === 1 ? true : false,
                                "NOMBRE": "SALIDA LUGAR",
                                "VALOR": getDateTimeText(servicio.SERVICIO_FECHAHORA_SALIDA_LUGAR),
                                "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHAHORA_SALIDA_LUGAR, servicio.SERVICIO_ESTADO),
                                "MOSRTAR_BOTON": true,
                                "CODIGO_HORA": 600
                              },
                              {
                                "POSICION": 5,
                                "VISIBLE": servicio.GESTION_HORAS.LLEGADA_DESTINO === 1 ? true : false,
                                "NOMBRE": "LLEGADA DESTINO",
                                "VALOR": getDateTimeText(servicio.SERVICIO_FECHAHORA_LLEGADA_DESTINO),
                                "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHAHORA_LLEGADA_DESTINO, servicio.SERVICIO_ESTADO),
                                "MOSRTAR_BOTON": true,
                                "CODIGO_HORA": 700
                              },
                              {
                                "POSICION": 6,
                                "VISIBLE": servicio.GESTION_HORAS.LIBRE_RETORNANDO === 1 ? true : false,
                                "NOMBRE": "LIBRE RETORNANDO",
                                "VALOR": getDateTimeText(servicio.SERVICIO_FECHAHORA_RETORNANDO),
                                "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHAHORA_RETORNANDO, servicio.SERVICIO_ESTADO),
                                "MOSRTAR_BOTON": true,
                                "CODIGO_HORA": 800
                              },
                              {
                                "POSICION": 7,
                                "VISIBLE": servicio.GESTION_HORAS.FINALIZADO === 1 ? true : false,
                                "NOMBRE": "FINALIZADO",
                                "VALOR": getDateTimeText(servicio.SERVICIO_FECHAHORA_FINALIZADO),
                                "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHAHORA_FINALIZADO, servicio.SERVICIO_ESTADO),
                                "MOSRTAR_BOTON": true,
                                "CODIGO_HORA": 900
                              },
                              {
                                "POSICION": 8,
                                "VISIBLE": servicio.GESTION_HORAS.ANULAR === 1 ? true : false,
                                "NOMBRE": "ANULADO",
                                "VALOR": getDateTimeText(servicio.SERVICIO_FECHAHORA_ANULADO),
                                "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHAHORA_ANULADO, servicio.SERVICIO_ESTADO),
                                "MOSRTAR_BOTON": true,
                                "CODIGO_HORA": 1000
                              }
                            ],
                            "SERVICIO_FECHAHORA_ANULADO": servicio.SERVICIO_FECHAHORA_ANULADO,
                            "SERVICIO_FECHAHORA_FINALIZADO": servicio.SERVICIO_FECHAHORA_FINALIZADO,
                            "SERVICIO_FECHAHORA_INICIO": servicio.SERVICIO_FECHAHORA_INICIO,
                            "SERVICIO_FECHAHORA_LLEGADA_DESTINO": servicio.SERVICIO_FECHAHORA_LLEGADA_DESTINO,
                            "SERVICIO_FECHAHORA_LLEGADA_LUGAR": servicio.SERVICIO_FECHAHORA_LLEGADA_LUGAR,
                            "SERVICIO_FECHAHORA_PETICION": servicio.SERVICIO_FECHAHORA_PETICION,
                            "SERVICIO_FECHAHORA_REALIZANDOSE": servicio.SERVICIO_FECHAHORA_REALIZANDOSE,
                            "SERVICIO_FECHAHORA_RETORNANDO": servicio.SERVICIO_FECHAHORA_RETORNANDO,
                            "SERVICIO_FECHAHORA_SALIDA_LUGAR": servicio.SERVICIO_FECHAHORA_SALIDA_LUGAR,
                            "SERVICIO_FECHA_HORA_SERVICIO": servicio.SERVICIO_FECHA_HORA_SERVICIO,
                            "SERVICIO_NSS": servicio.SERVICIO_NSS,
                            "SERVICIO_DNI": servicio.SERVICIO_DNI,
                            "SERVICIO_OBSERVACIONES": servicio.SERVICIO_OBSERVACIONES,
                            "SERVICIO_ORIGEN": servicio.SERVICIO_ORIGEN,
                            "SERVICIO_PACIENTE": servicio.SERVICIO_PACIENTE,
                            "TABLA": servicio.TABLA,
                            "TIPO_SERVICIO_ID": servicio.TIPO_SERVICIO_ID,
                            "TIPO_SERVICIO_TXT": servicio.TIPO_SERVICIO_TXT,
                          }
                        }
                      />
                    </div>
                  )
                })
                :
                <AlertaListaSinResultados />
            }
          </div>
        </div>
        {/* </>
            :
            <div className='content px-3'>
              <AlertaServiciosDesdeMovil />
            </div>
        } */}
      </div>
    </PageLayout >
  )
}