import React from 'react'
import 'animate.css'
import logo from '../images/logo192.png'

import { SideBarCloseButton } from './SideBarCloseButton'
import { SideBarMenu } from './SideBarMenu'
import { ChangeLog } from '../helpers/ChangeLog'

export const SideBar = () => {

  const showChangeLog = () => {
    ChangeLog()
  }

  return (
    <div id='sidebar-container'>
      <div id='sidebar-background'>
        <div className='sidebar animate__animated animate__slideInLeft animate__faster' id='sidebar'>
          <div className='d-flex align-items-center' id='sidebar-header'>
            <div className='me-auto'>
              <img src={logo} alt='Logo Transinsa | Ambulancias de Asturias' />
            </div>
            <SideBarCloseButton />
          </div>
          <div id='sidebar-content'>
            <SideBarMenu />
          </div>
          <div
            className='changelog d-flex align-items-center text-center justify-content-center' id='sidebar-footer' onClick={showChangeLog}>
            {`versión ${process.env.REACT_APP_VERSION}`}
          </div>
        </div>
      </div>
    </div>
  )
}
