import React from 'react'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { SolicitudCambioTurnoForm } from '../components/SolicitudCambioTurnoForm'
// import { NavLink } from 'react-router-dom'

export const SolicituCambioTurno = () => {
  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title='SOLICITUD DE CAMBIO DE TURNO' />
        <SolicitudCambioTurnoForm />
        {/* <div className='text-end mb-3'>
          <NavLink to='/registros' className='btn btn-primary me-1'>CANCELAR</NavLink>
          <button className="btn btn-primary">ACEPTAR</button>
        </div> */}
      </div>
    </PageLayout>
  )
}
