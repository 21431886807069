import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { CitaTaller } from '../components/CitaTaller'
import { AlertaListaSinResultados } from '../components/AlertaListaSinResultados'
import { citaTaller, obtenerListaCitasTaller } from '../redux/actions/citasTallerActions'

export const CitasTaller = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { inVehiculoId } = useSelector(({ loginReducer }) => loginReducer)
  const { listaCitasTaller } = useSelector(({ citasTallerReducer }) => citasTallerReducer)

  useEffect(() => {
    inVehiculoId > 0 && dispatch(obtenerListaCitasTaller(inVehiculoId))
    return () => {
      dispatch(citaTaller({}))
    }
  }, [dispatch, inVehiculoId])

  const nuevaCitaTaller = async () => {
    setTimeout(async () => {
      try {
        const url = `${process.env.REACT_APP_APIVEL}A_CITA_TALLER_CREAR.PRO?IN_VEHICULO_ID=${inVehiculoId}`
        const resp = await fetch(url)
        const data = await resp.json()
        navigate(`../ver-cita-taller/${data.CITA_TALLER_ID}`, { replace: true })
      } catch (error) {
        console.warn(error)
      }
    }, 100)
  }

  return (
    <PageLayout>
      <div className='content'>
        <TitlePage title='CITAS TALLER' />
        <div className='text-end mb-3 px-3'>
          <button className='btn btn-primary me-1' onClick={nuevaCitaTaller}>NUEVA CITA TALLER</button>
          <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
        </div>
        <div className='mb-3 px-3'>
          {
            listaCitasTaller.length > 0
              ?
              listaCitasTaller.map(citaTaller => {
                return (
                  <CitaTaller key={citaTaller.IN_CITATALLER_ID} citaTaller={citaTaller} />
                )
              })
              :
              <AlertaListaSinResultados />
          }
        </div>
      </div>
    </PageLayout>
  )
}
